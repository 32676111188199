<template>
  <div class="phone-unlink" :class="theme">
    <div v-if="!initialLoading">
      <StepStart
        v-if="currentStep === steps.start"
        :title="getTranslation('control_number')"
        :description="getTranslation('phone_unlink_type_below')"
        :input-phone-message="inputPhoneMessageText"
        :use-vercont-server="true"
        :show-promo-code-button="false"
      />

      <StepCheckCode
        v-if="currentStep === steps.checkCode"
        :title="getTranslation('control_number')"
      />

      <StepCaptcha v-if="currentStep === steps.captcha" />

      <StepMultipleLogins
        v-if="currentStep === steps.multipleLogins"
        :title="getTranslation('control_number')"
        @selectLogin="selectLogin"
      />

      <StepOther
        v-if="currentStep === steps.phoneNotFound"
        :title="getTranslation('control_number')"
        :button-text="getTranslation('try_again_2')"
        :result-step="true"
        icon-name="warning"
        @clickButton="flushProcess"
      >
        <template v-slot:description>
          <span v-html="getTranslation('unfortunately')" />
          <b v-text="formatPhone(phone)" />&#32;<span v-html="getTranslation('not_registered')" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.unlinkLogin"
        button-type="danger"
        :title="getTranslation('control_number')"
        :button-text="getTranslation('phone_unlink_number')"
        @clickButton="unlinkPhone"
      >
        <template v-slot:description>
          <span v-html="getTranslation('to_number')" />
          <b v-text="formatPhone(phone)" />&#32;<span
            v-html="getTranslation('phone_unlink_explain_0')"
          />
          <span v-text="$store.authAndReg.currentLogin" />
          <br />
          <span v-html="getTranslation('phone_unlink_explain_1')" />&#32;<span
            v-text="$store.authAndReg.currentLogin"
          />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.unlinkOneOfMultipleLogins"
        :title="getTranslation('control_number')"
        :button-text="getTranslation('phone_unlink_number')"
        button-type="danger"
        @clickButton="unlinkPhone"
      >
        <template v-slot:description>
          <span v-html="getTranslation('phone_unlink_warn_1')" />&#32;<span
            v-text="$store.authAndReg.currentLogin"
          />&#32;<span v-html="getTranslation('phone_unlink_warn_2')" />&#32;<b
            v-text="formatPhone(phone)"
          />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.fail"
        :title="getTranslation('oops_didnt_work')"
        :button-text="getTranslation('try_again_2')"
        icon-class-name="color-error"
        :result-step="true"
        icon-name="warning"
        @clickButton="flushProcess"
      >
        <template v-slot:description>
          <span v-html="getTranslation('phone_unlink_fail')" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.success"
        :title="getTranslation('all_ready')"
        icon-name="check"
        :result-step="true"
        :button-text="getTranslation('phone_unlink_another_login')"
        @clickButton="changeStep(steps.multipleLogins)"
      >
        <template v-slot:description>
          <span v-html="getTranslation('phone_unlink_done')" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.error"
        :title="getTranslation('something_went_wrong')"
        :button-text="getTranslation('try_again_2')"
        :error="true"
        icon-name="warning"
        :result-step="true"
        @clickButton="flushProcess"
      />

      <StepOther
        v-if="currentStep === steps.outdated"
        :title="getTranslation('control_number')"
        :button-text="getTranslation('try_again_2')"
        @clickButton="flushProcess"
      >
        <template v-slot:description>
          <span v-html="getTranslation('session_time_out')" />
          <br />
          <span v-html="getTranslation('try_again_1')" />
        </template>
      </StepOther>
    </div>

    <LoaderSpinner v-if="initialLoading" class="absolute-center-aligner" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';

import StepStart from 'src/components/auth/steps/StepStart.vue';
import StepCheckCode from 'src/components/auth/steps/StepCheckCode.vue';
import StepMultipleLogins from 'src/components/auth/steps/StepMultipleLogins.vue';
import StepOther from 'src/components/auth/steps/StepOther.vue';
import StepCaptcha from 'src/components/auth/steps/StepCaptcha.vue';
import { AUTH_STEPS, HTTP_CODES, REGISTRATION_SMS_CODE_LENGTH } from 'src/constants';
import { Watch } from 'vue-property-decorator';
import * as api from 'src/api';

@Component({
  components: {
    StepStart,
    StepCheckCode,
    StepMultipleLogins,
    StepOther,
    StepCaptcha,
  },
})
export default class PhoneUnlinkSM extends Auth {
  nextStepAfterGetLogins = AUTH_STEPS.unlinkLogin;

  @Watch('checkCode')
  onCheckCode(value: string) {
    if (this.isLoading) {
      return;
    }

    this.checkCode = this.checkCode.replace(/\D/g, '');
    if (value.length === REGISTRATION_SMS_CODE_LENGTH) {
      this.isLoading = true;
      this.verifyAndGetLogins(this.checkCode);
    }
  }

  get inputPhoneMessageText() {
    return this.getTranslation(
      this.incompletePhone ? 'incomplete_phone_reg' : 'phone_unsupported_reg_kt'
    );
  }

  async mounted() {
    this.$store.authAndReg.gaNameComponent = 'phone_unlink';
    this.$store.authAndReg.verificationComponentName = 'authentication';

    await this.checkUseRecaptcha();

    this.$nextTick(() => {
      this.$store.authAndReg.captchaRefreshValue++;
    });
    this.initialLoading = false;
  }

  selectLogin(login = '') {
    this.currentLogin = login ? login : this.$store.authAndReg.logins[0].login;
    this.changeStep(AUTH_STEPS.unlinkOneOfMultipleLogins);
  }

  async unlinkPhone() {
    try {
      await api.auth.contactsVerification.unlinkPhone({
        params: {
          login: this.currentLogin,
          'contacts-session-token': this.$store.verificationResult.sessionToken,
        },
      });
      this.changeStep(AUTH_STEPS.success);
      this.$store.authAndReg.logins = this.$store.authAndReg.logins.filter((val: any) => {
        return val.login !== this.currentLogin;
      });
      this.gaEvent({ category: 'phone_unlink', action: 'Нажатие кнопки "Отвязать номер"' });
    } catch (err) {
      this.gaEvent({
        category: 'phone_unlink',
        action: 'Нажатие кнопки "Отвязать номер"',
        error_text: err.message,
      });
      if (err.code === HTTP_CODES.NOT_FOUND) {
        this.changeStep(AUTH_STEPS.outdated);
      } else if (err.code === HTTP_CODES.BAD_REQUEST) {
        this.showErrorPage(err);
      } else {
        this.changeStep(AUTH_STEPS.fail);
      }
    }
  }
}
</script>

<style lang="scss">
@import 'src/styles/layouts/auth-layout';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.absolute-center-aligner:not(.loader-spinner) {
  max-width: 360px;

  // this is needed in order to show page properly with many multiple logins
  // on mobile devices in landscape mode or on a desktop with small window height
  @media (min-width: $mobile-l-min) and (max-height: 600px) {
    top: 100px;
    transform: translate(-50%, 0);
  }

  @include mobile-s {
    position: relative;
    top: auto;
    left: auto;
    padding: 48px 0 24px;
    transform: none;
  }

  .icon {
    display: inline-block;
    padding: 8px;
    margin-bottom: 24px;
    pointer-events: none;
    background-color: var(--alpha-light-3);
    border-radius: 100%;
  }

  .error {
    margin-bottom: 8px;
  }
}
</style>
