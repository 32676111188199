<template>
  <div class="step-provider">
    <h4 class="h3" v-html="getTranslation('registration_reg')" />
    <div v-if="isSmotreshka" class="description body1 mb-24">
      <span v-html="getTranslation('registration_provider_new')" />
    </div>

    <form novalidate autocomplete="off" @submit.prevent="$emit('finishRegistration')">
      <DropdownSequoia
        v-slot="{ value, opened, disabled, toggle }"
        ref="regions"
        :list="regions"
        :filter="filterRegion"
        :close-toggle="false"
        width="auto"
        class="mb-16"
        data-cy="regions"
        @select="setRegionId"
        @gaOpen="gaEventOnceAuth({ action: 'Открытие дропдауна «Регион»' })"
      >
        <div class="dropdown-input">
          <InputText
            :value="value"
            input-category="floating"
            autocomplete="off"
            :disabled="disabled"
            size="medium"
            required
            :label="getTranslation('region')"
            @input="onInputRegion"
            @focus="toggle"
          />
          <button class="dropdown-input-icon on-mobile" type="button" @click="toggle(true)">
            <IconSVG :svg="IconAngleDown" :direction="opened ? 'up' : ''" />
          </button>
        </div>
      </DropdownSequoia>

      <DropdownSequoia
        id="step-provider-value"
        ref="providers"
        v-slot="{ value, opened, disabled, toggle }"
        class="providers mb-0"
        :list="filteredProviders"
        :filter="filterProvider"
        :close-toggle="false"
        :disabled="!regionPicked || !filteredProviders.length"
        width="auto"
        data-cy="providers"
        @select="setProviderId"
        @gaOpen="gaEventOnceAuth({ action: 'Открытие дропдауна «Провайдер»' })"
      >
        <div class="dropdown-input">
          <InputText
            :value="value"
            input-category="floating"
            autocomplete="off"
            size="medium"
            :disabled="disabled"
            required
            :label="getTranslation('internet_provider')"
            @input="onInputProvider"
            @focus="toggle"
          />
          <div class="dropdown-input-icon" @click="toggle(true)">
            <IconSVG :svg="IconAngleDown" :direction="opened ? 'up' : ''" />
          </div>
        </div>
      </DropdownSequoia>

      <InputText
        v-if="regionPicked && showCustomProvider"
        v-model="$store.authAndReg.register.providerName"
        input-category="floating"
        size="medium"
        class="mt-24"
        required
        :label="getTranslation('enter_provider')"
        @focus="gaEventOnceAuth({ action: 'Фокус в поле «Введите название провайдера»' })"
      />

      <InputCheckbox
        v-if="abTestCheck"
        class="provider-checkbox"
        :value="noProvider"
        @input="confirmNoProvider"
      >
        <span v-html="getTranslation('provider_is_not_on_the_list')" />
      </InputCheckbox>

      <ErrorSequoia :messages="errorMessages" data-cy="provider" class="ml-16" />

      <ButtonDefault
        class="mt-16"
        data-cy="signup"
        size="medium"
        :full-width="true"
        :is-loading="isLoading"
        :disabled="isSubmitCaptchaButtonDisabled"
      >
        <span v-html="getTranslation('signup')" />
      </ButtonDefault>
    </form>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import InputCheckbox from 'src/components/ui/input/InputCheckbox.vue';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import { Ref } from 'vue-property-decorator';
import Auth from 'src/components/auth/Auth';
import IconAngleDown from 'src/svg/angle-down.svg';

@Component({
  components: {
    InputCheckbox,
    DropdownSequoia,
    ButtonDropdown,
  },
})
export default class StepProvider extends Auth {
  noProvider = true;
  regionPicked = false;
  filteredProviders: any[] = [];
  showCustomProvider = false;
  filterRegion = '';
  filterProvider = '';
  IconAngleDown = IconAngleDown;

  @Ref('regions')
  refRegions?: any;

  @Ref('providers')
  refProviders?: any;

  get abTestCheck() {
    return this.$route?.query.opt === 'b';
  }

  get isSubmitCaptchaButtonDisabled() {
    return (
      this.isLoading ||
      (this.$route?.query.from !== 'watch' &&
        !(this.$store.authAndReg.register.regionId && !!this.$store.authAndReg.register.providerId))
    );
  }

  get regions() {
    return this.$store.regions.map((region) => ({
      ...region,
      value: region.name,
      key: region.id,
      selected: region.id === this.$store.authAndReg.register.regionId,
    }));
  }

  onInputRegion(value: string) {
    this.filterRegion = value;
  }

  onInputProvider(value: string) {
    this.filterProvider = value;
  }

  setRegionId(regionSelected: TDropdownItem | string) {
    const regionId = typeof regionSelected === 'object' ? regionSelected?.id : regionSelected;
    this.$store.authAndReg.register.regionId = regionId || '';
    this.regionPicked = !!regionId;

    if (!this.noProvider) {
      this.$store.authAndReg.register.providerId = this.$store.authAndReg.register.providerName =
        '';
    }

    if (!Array.isArray(this.$store.providers)) {
      this.$store.providers = [];
    }

    const filteredProviders = this.$store.providers
      .filter((provider) => {
        if (!Array.isArray(provider.regions)) {
          return false;
        }
        return provider.regions.indexOf(regionId) !== -1;
      })
      .map((provider) => ({
        ...provider,
        key: provider.id,
        value: provider.name,
      }));

    this.filteredProviders = [
      { id: '0', value: this.getTranslation('not_on_the_list'), key: 0 },
      ...filteredProviders,
    ];

    this.filterRegion = '';

    if (!this.noProvider) {
      this.showCustomProvider = !this.filteredProviders?.length;
    }
  }

  setProviderId(providerSelected: TDropdownItem) {
    this.showCustomProvider = providerSelected.id === '0';
    this.$store.authAndReg.register.providerId = providerSelected.id || '';
    this.filterProvider = '';
    if (this.abTestCheck) {
      this.noProvider = providerSelected.id === '0';
    }
  }

  confirmNoProvider() {
    this.noProvider = !this.noProvider;
    if (this.noProvider) {
      this.pickDefaultProviderForAbTest();
    } else {
      this.refProviders.clearCurrentValue();
      this.$store.authAndReg.register.providerId = '';
      this.showCustomProvider = false;
    }
  }

  pickDefaultProviderForAbTest() {
    this.filteredProviders = [
      { id: '0', value: this.getTranslation('not_on_the_list'), key: 0 },
      ...this.filteredProviders,
    ];
  }

  mounted() {
    if (this.$store.authAndReg.register.regionId) {
      this.setRegionId(this.$store.authAndReg.register.regionId);
    }
    this.$store.authAndReg.recommendProvider = '';
    /* if (this.abTestCheck) {
      this.refRegions.pickItem(this.$store.regions[0]);
      this.pickDefaultProviderForAbTest();
    }*/
  }
}
</script>

<style lang="scss" scoped>
.providers {
  z-index: calc(var(--z-dropdown) - 1);
}

.dropdown-input {
  position: relative;
  width: 100%;

  .dropdown-input-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  }
}
</style>
