<template>
  <div class="reg-completed-trials" :class="[$store.theme, { 'ab-test': abTestCheck }]">
    <h1 class="title" v-html="title" />

    <div
      class="description body1"
      v-html="getTranslationForAuthAndRegTrial('registrationSubtitle')"
    />

    <div
      class="choose-offer h5 font-secondary"
      v-html="getTranslationForAuthAndRegTrial('registrationSelectOfferTitle')"
    />

    <div class="background-wrapper">
      <svg class="background" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon class="triangle" points="0,100 0,60 100,8 100,100" />
      </svg>

      <TrialSubscriptionOffers class="offers" @more="onShowMore" />

      <TrialSubscriptionOffer
        v-if="abTestCheck"
        v-for="offer in offers"
        :id="offer.id"
        :key="offer.id"
        class="slide col-desktop-3 col-tablet-4 col-mobile-2"
        :active="offer.id === currentOfferId"
        :name="offer.title"
        :channels-count="offer.channels.length"
        :trial-days="offer.trialDays"
        :rendering-data="offer.renderingData"
        @more="onShowMore"
      />

      <div class="button-and-note-wrap">
        <div class="button-connect-wrap">
          <ButtonDefault
            view="primary"
            size="medium"
            class="button-connect"
            data-cy="trials-buy"
            @click="onBuyOffer"
          >
            <span v-html="getTranslationForAuthAndRegTrial('callToAction')" />
          </ButtonDefault>
          <ButtonOfferDetails
            class="button-offer-more"
            :class="abTestCheck ? 'visible-tablet' : 'visible-mobile-and-tablet '"
            @click="onShowMore"
          />
        </div>
        <div class="sub-note body2 color-tertiary">
          <span v-html="prolongationDateDesktopTranslation" />&#32;<span
            v-text="currentOfferTrialEndDate"
          />
        </div>
        <div v-if="abTestCheck" class="sub-note body2 ab-test">
          <span v-html="getTranslation('prolongation_date_mobile')" />&#32;<span
            v-text="currentOfferTrialEndDate"
          />
        </div>
      </div>
    </div>

    <div class="container-1440">
      <button
        type="button"
        class="link nav-hidden continue-without-subscription caption2"
        @click="showExitConfirmation = true"
      >
        <span class="inner" v-html="getTranslationForAuthAndRegTrial('proceedNoSubscription')" />
      </button>

      <div
        class="rules color-tertiary body2"
        v-html="getTranslationForAuthAndRegTrial('disclaimer')"
      />
    </div>

    <ModalSequoia
      v-if="showOfferDetails"
      type="fixed"
      size="large"
      class="modal-details"
      :title="offer.title"
      @close="showOfferDetails = false"
    >
      <OfferDetails class="with-side-and-bottom-padding" @buyOffer="onBuyOffer" />
    </ModalSequoia>

    <ModalSequoia
      v-if="showExitConfirmation"
      type="fixed"
      size="small"
      :with-close-button="true"
      @close="showExitConfirmation = false"
    >
      <ConfirmExitModal class="with-side-and-bottom-padding" @back="showExitConfirmation = false" />
    </ModalSequoia>

    <ModalSequoia v-if="showGiftModal" type="fixed" @close="onCloseGiftModal">
      <GiftModal @close="onCloseGiftModal" />
    </ModalSequoia>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import ButtonOfferDetails from 'src/components/auth/reg-completed/trials/ButtonOfferDetails.vue';
import { currentOfferIdSelector } from 'src/store/registration/selectors';
import TrialSubscriptionOffers from 'src/components/auth/reg-completed/trials/trials-list/TrialSubscriptionOffers.vue';
import TrialSubscriptionOffer from 'src/components/auth/reg-completed/trials/trials-list/TrialSubscriptionOffer.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import ConfirmExitModal from 'src/components/auth/reg-completed/trials/ConfirmExitModal.vue';
import IconSVG from 'src/components/IconSVG.vue';
import OfferDetails from 'src/components/auth/reg-completed/acquiring/offer-details/OfferDetails.vue';
import GiftModal from 'src/components/auth/reg-completed/trials/GiftModal.vue';
import NotificationSimple from 'src/components/ui/notifications/NotificationSimple.vue';
import { dateAfterNDays } from 'src/utils/time/date';
import logger from 'src/utils/logger';

const log = logger('reg-completed-trials');

@Component({
  components: {
    ButtonOfferDetails,
    NotificationSimple,
    GiftModal,
    IconSVG,
    ButtonDefault,
    TrialSubscriptionOffer,
    TrialSubscriptionOffers,
    ModalSequoia,
    ConfirmExitModal,
    OfferDetails,
  },
})
export default class RegCompletedTrials extends SequoiaComponent {
  showOfferDetails = false;
  showExitConfirmation = false;
  showGiftModal = false;

  get abTestCheck() {
    return this.$route.query.opt === 'b';
  }

  get offers() {
    return selectors.registration.offersSelector(this.$store);
  }

  get currentOfferId() {
    return selectors.registration.currentOfferIdSelector(this.$store);
  }

  get activeTrialIdFromAdmin() {
    return selectors.common.activeTrialIdFromAdmin(this.$store);
  }

  get maxTrialDays() {
    let maxTrialDays = 0;
    this.offers
      .filter((offer) => offer.trialAvailable)
      .forEach((offer) => {
        maxTrialDays = Math.max(offer.trialDays, maxTrialDays);
      });
    return maxTrialDays;
  }

  get hasGiftModal() {
    const channels = this.offer?.channels || [];
    const trialChannels = this.offer?.trialChannels || [];
    return trialChannels.length > channels.length;
  }

  get confirmationUrl() {
    return selectors.registration.confirmationUrlSelector(this.$store);
  }

  get offer() {
    return selectors.registration.currentOfferSelector(this.$store);
  }

  get title() {
    const title = this.getTranslationForAuthAndRegTrial('registrationTitle') || '';
    return title
      .replace('%maxTrialNum%', this.maxTrialDays.toString())
      .replace('%maxTrialDays%', this.getDaysWord(this.maxTrialDays));
  }

  get prolongationDateDesktopTranslation() {
    let translation = this.getTranslation('prolongation_date') || '';
    if (this.offer?.title) {
      translation = translation.replace('%offerTitle%', this.offer.title);
    }
    return translation;
  }

  get createSubscriptionError() {
    return this.$store.registration.createSubscriptionError;
  }

  get currentOfferTrialEndDate() {
    if (this.offer) {
      return dateAfterNDays(this.offer.trialDays);
    }
  }

  async mounted() {
    this.gaEvent({ category: 'acquiring', action: 'Показ диалога оплаты' });
    const activeTrialId = this.offers.find((offer) => offer.id === this.activeTrialIdFromAdmin)?.id;
    this.setCurrentOfferId(activeTrialId || this.offers[1].id);
  }

  getTranslationForAuthAndRegTrial(key: string) {
    return actions.authAndReg.getTranslationForAuthAndRegTrial(this.$store, key);
  }

  setCurrentOfferId(id: string) {
    actions.registration.setCurrentOfferId(this.$store, id);
  }

  onShowMore() {
    this.showOfferDetails = true;
  }

  async onBuyOffer() {
    this.showOfferDetails = false;
    await actions.registration.createSubscription(this.$store);
    if (this.createSubscriptionError) {
      actions.registration.disableTrials(this.$store);
    }
    if (!this.confirmationUrl) {
      log.error('no confirmation url');
      return;
    }
    if (this.hasGiftModal) {
      this.showGiftModal = true;
    } else {
      window.location.href = this.confirmationUrl;
    }

    this.gaEvent({
      category: 'acquiring',
      action: 'Клик по кнопке "Подключить"',
      offer_id: currentOfferIdSelector(this.$store),
    });
  }

  onCloseGiftModal() {
    this.showGiftModal = false;
    window.location.href = this.confirmationUrl;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/link';

.reg-completed-trials {
  width: 100%;

  .title {
    @extend %heading2;

    margin: 48px 0 24px;
    text-align: center;

    @include mobile-and-tablet {
      @include heading3;
    }

    @include tablet {
      margin-top: 40px;
      margin-bottom: 16px;
      text-align: left;
    }

    @include mobile {
      margin: 28px 0;
      text-align: left;
    }
  }

  .description {
    max-width: 480px;
    margin: 0 auto 48px;
    text-align: center;

    @include mobile-and-tablet {
      padding-bottom: 24px;
      margin: 0 0 24px;
      text-align: left;
      border-bottom-color: var(--alpha-light-3);
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .choose-offer {
    padding-bottom: 48px;
    margin-bottom: 0;
    text-align: center;

    @include mobile-and-tablet {
      padding-bottom: 24px;
      text-align: left;
    }

    @include mobile {
      padding-bottom: 32px;
    }
  }

  .offers {
    margin-bottom: 64px;
  }

  .button-and-note-wrap {
    text-align: center;

    @include mobile-and-tablet {
      text-align: left;
    }
  }

  .button-connect-wrap {
    @include tablet {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .button-connect {
    width: 256px;
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 0;
    }

    @include mobile {
      width: 100%;
    }
  }

  .sub-note {
    padding-bottom: 32px;

    @include mobile {
      padding-bottom: 24px;
    }

    &.ab-test {
      display: none;
    }
  }

  .background-wrapper {
    position: relative;
    margin-bottom: 32px;

    @include mobile-and-tablet {
      margin-bottom: 0;
    }
  }

  .button-offer-more {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 0;
      margin-left: 48px;
    }

    @include mobile {
      margin-bottom: 32px;
    }
  }

  .background {
    position: absolute;
    bottom: 0;
    left: -64px;
    width: calc(100% + 128px);
    height: 100%;

    @include mobile-and-tablet {
      display: none;
    }
  }

  .triangle {
    fill: rgba(0, 0, 0, 0.02);
  }

  .continue-without-subscription {
    display: inline-flex;
    align-items: center;
    margin-bottom: 32px;
    border-bottom: 0;

    @include mobile {
      margin-bottom: 16px;
    }

    .inner {
      margin-right: 4px;
      border-bottom: 1px solid transparent;
    }

    @include devices-with-hover {
      &:hover {
        .inner {
          border-bottom-color: currentColor;
        }

        .icon {
          transform: translateX(8px);
        }
      }
    }

    &:active {
      color: var(--c-light-font-secondary);
    }
  }

  .rules {
    max-width: 680px;
    margin-top: 34px;

    @include mobile-and-tablet {
      max-width: 100%;
    }

    &::v-deep p {
      margin-bottom: 6px;
    }

    &::v-deep a {
      @extend %light-link-nav-underlined;
    }
  }

  .modal-details::v-deep .dialog > .title {
    padding-bottom: 0;
    @extend %heading2;
  }

  // AB Test
  &.ab-test {
    .trial-subscription-offer {
      display: none;
      @include mobile {
        display: block;
      }
    }

    @include mobile {
      .trial-subscription-offers {
        display: none;
      }

      .choose-offer {
        display: block;
        text-align: left;
      }

      .button-and-note-wrap {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: var(--z-8);
        width: 100%;
        padding: 16px 24px 24px;
        background-color: var(--c-light-100);
        border-radius: 8px 8px 0 0;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      }

      .button-connect {
        margin-bottom: 8px;
      }

      .button-offer-more {
        display: none;
      }

      .continue-without-subscription {
        margin-top: 0;
        margin-bottom: 24px;
      }

      .sub-note {
        &.ab-test {
          display: block;
          padding-bottom: 0;
        }

        &:not(.ab-test) {
          display: none;
        }
      }

      .rules {
        padding-bottom: 164px;
      }
    }
  }

  &.dark {
    .rules {
      &::v-deep a {
        @extend %dark-link-nav-underlined;
      }
    }

    .ab-test {
      .mobile {
        .button-and-note-wrap {
          background-color: var(--c-dark-100);
        }
      }
    }

    .continue-without-subscription {
      &:active {
        color: var(--c-dark-font-secondary);
      }
    }

    .description {
      @include mobile-and-tablet {
        border-bottom-color: var(--alpha-dark-3);
      }
    }
  }
}
</style>
