<template>
  <div>
    <StepAuth
      v-if="currentStep === steps.auth"
      :title="getTranslation('enter_reg')"
      :sign-in-content-block="true"
      :button-text="getTranslation('signin')"
    />

    <StepOther
      v-if="currentStep === steps.promoCodeUserExist"
      :title="getTranslation('auth_promo_code_error_title')"
      :error="true"
      icon-name="IconCrossCircled"
      icon-class-name="color-error"
      :button-text="getTranslation('auth_promo_code_success_btn')"
      @clickButton="clickEnter"
    >
      <template v-slot:description>
        <span v-html="getTranslation('auth_promo_code_userexist_text')" />
      </template>
    </StepOther>

    <StepOther
      v-if="currentStep === steps.promoCodeSuccess"
      :title="getTranslation('auth_promo_code_success_title')"
      :button-text="getTranslation('auth_promo_code_success_btn')"
      icon-class-name="color-success"
      icon-name="IconCheckCircled"
      @clickButton="clickEnter"
    >
      <template v-slot:description>
        <span
          v-html="
            $store.authAndReg.promoCodeResultText || getTranslation('auth_promo_code_success_text')
          "
        />
      </template>
    </StepOther>

    <div v-if="textBelowLoginButton" class="text-center" v-html="textBelowLoginButton" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { offAutocomplete } from 'src/utils/directives';
import Auth from 'src/components/auth/Auth';
import StepAuth from 'src/components/auth/steps/StepAuth.vue';
import StepOther from 'src/components/auth/steps/StepOther.vue';
import { wlDetector } from 'src/utils';
import logger from 'src/utils/logger';
import { AUTH_STEPS } from 'src/constants';

const log = logger('auth-wl');

@Component({
  components: { StepAuth, StepOther },
  directives: {
    offAutocomplete,
  },
})
export default class AuthWL extends Auth {
  get textBelowLoginButton() {
    return this.getTranslation(
      this.$store.authAndReg.textBelowLoginButtonId
        ? this.$store.authAndReg.textBelowLoginButtonId
        : ''
    );
  }

  get isUztel() {
    return wlDetector().isUztel;
  }

  created() {
    this.$store.isPhoneMode = false;
    this.changeStep(AUTH_STEPS.auth);
  }

  clickEnter() {
    this.$store.authAndReg.promoCode = '';
    this.$store.authAndReg.promoCodeValid = false;
    this.auth();
  }
}
</script>
