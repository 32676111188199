<template>
  <AuthLayout>
    <div class="auth-header">
      <LogoSequoia />
    </div>
    <div class="auth-content">
      <AuthSlider v-if="$store.authAndReg.currentStep !== 'simPassword'" />
      <MegafonSim v-else />

      <div class="auth-forms">
        <div class="auth-forms-inner">
          <AuthSM v-if="isN3" />
          <AuthAndRegSM v-else-if="isSmotreshka" />
          <AuthAndRegBySmsWL v-else-if="isAuthAndRegBySmsWL" />
          <template v-else>
            <AuthWL />
            <router-link
              v-if="$store.authAndReg.restorePassword && $store.authAndReg.restorePassword.show"
              class="link nav-underlined"
              to="/reset"
              v-html="getTranslation('forgot_password')"
            />
          </template>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaPage } from 'src/mixins';
import { wlDetector } from 'src/utils';
import AuthLayout from 'src/layouts/AuthLayout.vue';
import AuthSM from 'src/components/auth/AuthSM.vue';
import AuthAndRegSM from 'src/components/auth/AuthAndRegSM.vue';
import AuthWL from 'src/components/auth/AuthWL.vue';
import AuthAndRegBySmsWL from 'src/components/auth/AuthAndRegBySmsWL.vue';
import LogoSequoia from 'src/components/auth/parts/LogoSequoia.vue';
import AuthSlider from 'src/components/auth/parts/AuthSlider.vue';
import MegafonSim from 'src/components/auth/parts/MegafonSim.vue';
import { actions } from 'src/store/actions';

@Component({
  components: {
    AuthLayout,
    AuthSM,
    AuthWL,
    AuthAndRegBySmsWL,
    LogoSequoia,
    AuthSlider,
    MegafonSim,
    AuthAndRegSM,
  },
})
export default class AuthPage extends SequoiaPage {
  get isAuthAndRegBySmsWL() {
    return (
      wlDetector().isKt ||
      wlDetector().isMegacom ||
      wlDetector().isTele2 ||
      wlDetector().isAltel ||
      wlDetector().isUztel ||
      wlDetector().isMegafon ||
      wlDetector().isIntersv ||
      (this.isMts && !this.isAuthWithPassword)
    );
  }

  get isSmotreshka() {
    return wlDetector().isSmotreshka;
  }

  get isMts() {
    return wlDetector().isMts;
  }

  get isN3() {
    return wlDetector().isN3;
  }

  get isAuthWithPassword() {
    return this.$store.authAndReg.isAuthWithPassword;
  }

  getMetaInfo() {
    return {
      title: this.getTranslation('login_meta_title'),
      meta: [
        {
          name: 'description',
          content: this.getTranslation('login_meta_description'),
        },
        {
          name: 'keywords',
          content: this.getTranslation('login_meta_keywords'),
        },
      ],
    };
  }

  mounted() {
    actions.common.hideNotificationAuthAndReg(this.$store);
  }
}
</script>
