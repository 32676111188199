<template>
  <AuthLayout v-if="!withAcquiring" ref="auth">
    <div class="auth-header">
      <LogoSequoia
        :is-provider="!!providerIdFromUrl"
        :class="{ 'pointer-events-none': isSmotreshkaRegPage }"
      />
    </div>

    <div class="auth-content">
      <AuthSlider v-if="$store.authAndReg.currentStep !== 'simPassword'" />
      <MegafonSim v-else />

      <div class="auth-forms">
        <div class="auth-forms-inner registration">
          <AuthAndRegSM v-if="isSmotreshka || isN3" :scroll-block="refAuth" />
          <RegWL v-else />
        </div>
      </div>
    </div>
  </AuthLayout>

  <RegCompletedLayout v-else :with-background="!canApplyTrials">
    <RegCompleted :has-trials="canApplyTrials" />
  </RegCompletedLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { selectors } from 'src/store/selectors';
import { wlDetector } from 'src/utils';
import LogoSequoia from 'src/components/auth/parts/LogoSequoia.vue';
import AuthLayout from 'src/layouts/AuthLayout.vue';
import RegCompletedLayout from 'src/layouts/RegCompletedLayout.vue';
import AuthAndRegSM from 'src/components/auth/AuthAndRegSM.vue';
import RegWL from 'src/components/auth/RegWL.vue';
import RegCompleted from 'src/components/auth/reg-completed/RegCompleted.vue';
import { actions } from 'src/store/actions';
import { DEBUG_ACQUIRING_AFTER_REGISTRATION } from 'src/constants';
import { SequoiaPage } from 'src/mixins';
import AuthSlider from 'src/components/auth/parts/AuthSlider.vue';
import MegafonSim from 'src/components/auth/parts/MegafonSim.vue';
import { Ref } from 'vue-property-decorator';

@Component({
  components: {
    AuthLayout,
    AuthAndRegSM,
    RegWL,
    LogoSequoia,
    RegCompleted,
    RegCompletedLayout,
    AuthSlider,
    MegafonSim,
  },
})
export default class RegPage extends SequoiaPage {
  providerIdFromUrl = '';

  @Ref('auth')
  refAuth!: AuthLayout;

  get isSmotreshka() {
    return wlDetector().isSmotreshka;
  }

  get isN3() {
    return wlDetector().isN3;
  }

  get isSmotreshkaRegPage() {
    return this.isSmotreshka && this.$route.name === 'reg';
  }

  get offers() {
    return selectors.registration.offersSelector(this.$store);
  }

  get canApplyTrials() {
    return selectors.registration.canApplyTrialsSelector(this.$store);
  }

  get withAcquiring() {
    return selectors.registration.withAcquiringSelector(this.$store);
  }

  getMetaInfo() {
    return {
      title: this.getTranslation('register_meta_title'),
      meta: [
        {
          name: 'description',
          content: this.getTranslation('register_meta_description'),
        },
        {
          name: 'keywords',
          content: this.getTranslation('register_meta_keywords'),
        },
      ],
    };
  }

  mounted() {
    this.providerIdFromUrl = (this.$route.query.provider as string) || '';

    actions.common.hideNotificationAuthAndReg(this.$store);

    if (DEBUG_ACQUIRING_AFTER_REGISTRATION) {
      actions.registration.loadSubscriptionOptions(this.$store);
      actions.registration.loadTrialStatusAfterRegistration(this.$store);
    }
  }
}
</script>
