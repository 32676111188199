<template>
  <div class="form-container">
    <form
      v-if="$store.authAndReg.registration.usePhoneRegistration && !registerSuccess"
      novalidate
      autocomplete="off"
      @submit.prevent="handleSignUp"
    >
      <InputText
        v-model="email"
        input-category="floating"
        type="tel"
        size="medium"
        required
        :label="getTranslation('phone_number_reg')"
        :status="statusForPhoneInput"
        :message="messageForPhoneInput"
        @keypress="onInput"
        @onCompletePhoneCallback="onCompletePhoneCallback"
      />

      <InputText
        v-model="captchaValue"
        input-category="floating"
        size="medium"
        required
        class="mb-16"
        :with-captcha="true"
        :use-vercont-server="true"
        :maxlength="6"
        :label="getTranslation('code_reg')"
        :description="getTranslation('enter_digits_left')"
        :status="statusForCaptchaInput"
        :message="messageForCaptchaInput"
      />

      <template v-for="checkbox in signUpCheckboxes">
        <InputCheckbox :key="checkbox.id" v-model="checkbox.value">
          <span v-html="checkbox.content" />
        </InputCheckbox>
      </template>

      <div v-if="errorMessages.length" class="errors-container mt-8 mb-16">
        <div v-for="error in errorMessages" class="error" v-text="error" />
      </div>

      <ButtonDefault
        class="mt-8"
        size="medium"
        :full-width="true"
        :is-loading="isLoading"
        :disabled="isSubmitCaptchaButtonDisabled"
      >
        <span v-html="getTranslation('create_account')" />
      </ButtonDefault>

      <div v-if="textBelowRegButton" class="text-center" v-html="textBelowRegButton" />

      <div class="sign-up-content" v-html="signUpContentBlock" />
    </form>

    <SuccessMessage
      v-else-if="registerSuccess"
      :message="getTranslation('phone_register_success')"
    />
    <div v-else v-html="signUpContentBlock" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';
import { REGISTRATION_CAPTCHA_LENGTH } from 'src/constants';
import SuccessMessage from 'src/components/auth/parts/SuccessMessage.vue';
import { wlDetector } from 'src/utils';
import { cleanupPhone } from 'src/utils/phone';

import * as api from 'src/api';

@Component({
  components: {
    SuccessMessage,
  },
})
export default class RegistrationWL extends Auth {
  email = '';
  signUpCheckboxes: Array<any> = [];
  registerSuccess = false;

  get isSubmitCaptchaButtonDisabled() {
    return (
      this.isLoading ||
      (this.$store.authAndReg.phoneMask.use &&
        (this.incompletePhoneInitialFlag || this.incompletePhone || this.phoneUnsupported)) ||
      this.captchaValue.length < REGISTRATION_CAPTCHA_LENGTH ||
      this.signUpCheckboxes.some((checkbox) => !checkbox.value)
    );
  }

  get signUpContentBlock() {
    return this.getByLanguage(this.$store.authAndReg.registration?.contentBlock);
  }

  mounted() {
    if (wlDetector().isKcell) {
      this.$store.isPhoneMode = false;
    }

    if (this.$store.authAndReg.registration) {
      this.signUpCheckboxes = this.$store.authAndReg.registration?.checkboxes?.map((checkbox) => ({
        value: false,
        content: this.getByLanguage(checkbox.contentBlock),
        id: checkbox.contentBlockId,
      }));
    }
  }

  async handleSignUp() {
    if (this.isLoading || this.isSubmitCaptchaButtonDisabled) {
      return;
    }

    this.isLoading = true;
    this.errors.length = 0;
    this.errorMessages.length = 0;

    try {
      await api.auth.registerByPhone({ data: { phone: cleanupPhone(this.email, false) } });
      this.registerSuccess = true;
    } catch (err) {
      this.isLoading = false;
      this.$store.authAndReg.captchaRefreshValue++;
      this.handleError(err);
    }
  }
}
</script>
