<template>
  <AuthLayout>
    <div class="auth-header">
      <LogoSequoia />
    </div>
    <div class="auth-content">
      <AuthSlider />
      <div class="auth-forms">
        <div class="auth-forms-inner">
          <PhoneUnlinkSM />
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import LogoSequoia from 'src/components/auth/parts/LogoSequoia.vue';
import PhoneUnlinkSM from 'src/components/auth/PhoneUnlinkSM.vue';
import AuthLayout from 'src/layouts/AuthLayout.vue';
import AuthSlider from 'src/components/auth/parts/AuthSlider.vue';

@Component({
  components: { LogoSequoia, PhoneUnlinkSM, AuthLayout, AuthSlider },
})
export default class PhoneUnlinkPage extends Vue {}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.responsive-container {
  padding-top: 32px;

  @include mobile {
    padding-top: 24px;
  }
}
</style>
