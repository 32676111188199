<template>
  <button
    class="button-offer-details more body2"
    :class="theme"
    type="button"
    @click="$emit('click')"
  >
    <span v-html="getTranslation('more_about_sub')" />
  </button>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class ButtonOfferDetails extends SequoiaComponent {
  @Prop()
  themeForced?: 'light' | 'dark';
  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

.button-offer-details {
  height: 20px;
  color: var(--c-light-font-secondary);
  border-bottom: 1px dashed;

  @include devices-with-hover {
    &:hover {
      border-bottom-style: solid;
    }
  }

  &.dark {
    color: var(--c-dark-font-secondary);
  }
}
</style>
