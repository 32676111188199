<template>
  <div class="step-auth-mts">
    <h4 v-if="title" class="h3 mb-24 promocode-title">
      <span v-html="title" />
      <BadgeSequoia
        v-if="$store.authAndReg.promoCodeValid"
        class="promocode-badge"
        :text="$store.authAndReg.canonicalValue"
        :icon="IconGift"
        type="accent"
      />
    </h4>

    <InputText
      v-model="phone"
      input-category="floating"
      type="tel"
      size="medium"
      required
      :label="getTranslation('phone_number_reg')"
      @keypress="onInput"
    />

    <ButtonDefault class="mt-16" size="medium" :full-width="true" @click="$emit('clickButton')">
      <span v-html="getTranslation('mts_ask_code')" />
    </ButtonDefault>

    <ButtonDefault
      class="mt-16"
      :full-width="true"
      size="medium"
      view="secondary"
      @click="$emit('clickButton2')"
    >
      <span v-html="getTranslation('mts_login_with_password')" />
    </ButtonDefault>

    <ButtonDefault
      v-if="shouldShowPromoCodeButton"
      class="mt-16"
      type="button"
      view="secondary"
      size="medium"
      :with-icon="true"
      :full-width="true"
      @click="changeStep(steps.promoCode)"
    >
      <IconSVG :svg="IconGift" />
      <span v-html="getTranslation('i_have_promo_code')" />
    </ButtonDefault>

    <div v-if="signInContentBlock" class="content-block mt-24" v-html="signInContentBlock" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';
import { Prop } from 'vue-property-decorator';

@Component
export default class AuthByMts extends Auth {
  @Prop({ default: '' })
  title!: string;

  get signInContentBlock() {
    return this.getByLanguage(this.$store.authAndReg?.contentBlock);
  }

  get shouldShowPromoCodeButton() {
    return !this.$store.authAndReg.promoCodeValid && this.$store.authAndReg?.usePromoCodes;
  }

  mounted() {
    this.$store.authAndReg.isSmsAuth = this.$store.authAndReg.isAuthWithPassword = false;
  }
}
</script>
