<template>
  <div class="step-multiple-logins">
    <h4 v-if="title" class="h3 mb-24" v-html="title" />

    <div class="description mb-24">
      <span v-html="getTranslation('to_number')" />
      <b v-text="formatPhone(phone)" />
      <span v-html="getTranslation('linked_accounts')" />
      <div v-if="description" v-html="description" />
    </div>

    <div class="mt-24 auth-scroll" :class="{ 'scroll-wrap': $store.authAndReg.logins.length > 4 }">
      <div class="description mr-8">
        <ButtonTableCell
          v-for="login in logins"
          :key="login.login"
          :title="login.login"
          :caption="login.name"
          :navigation-icon="IconAngleRight"
          :full-width="true"
          :is-loading="login.isLoading"
          @click="selectLogin(login)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Auth from 'src/components/auth/Auth';
import ButtonTableCell from 'src/components/ui/buttons/ButtonTableCell.vue';
import IconAngleRight from 'src/svg/angle-right.svg';
import { TLogin } from 'src/store/auth-and-reg/types';

@Component({
  components: {
    ButtonTableCell,
  },
})
export default class StepMultipleLogins extends Auth {
  IconAngleRight = IconAngleRight;
  selectedLogin: string | null = null;

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  description!: string;

  @Prop({ default: false })
  showName!: boolean;

  get logins() {
    return this.$store.authAndReg.logins.map((login: TLogin) => ({
      ...login,
      isLoading: this.selectedLogin === login.login,
    }));
  }

  selectLogin(login: TLogin) {
    this.selectedLogin = login.login;
    this.$emit('selectLogin', login.login);
  }
}
</script>
