<template>
  <div v-if="offer" class="offer-details">
    <BadgeSequoia
      v-if="offer.trialDays"
      type="accent"
      :text="getTranslation('trial_days').replace('%trialPeriod%', offer.trialDays.toString())"
    />

    <div class="price bold" v-text="price" />
    <div v-if="offer.channels.length" class="channels-count" v-text="channelsCount" />
    <div v-if="vodSourcesList" class="vod body2" v-text="vodSourcesList" />

    <ButtonDefault class="button-buy-offer" @click="$emit('buyOffer')">
      <span v-html="getTranslation('connect')" />
    </ButtonDefault>

    <div v-for="genre in genres" :key="genre.title">
      <OfferDetailsChannels :genre="genre" :channels="getChannels(genre)" />
    </div>

    <div class="disclaimer body2" v-html="disclaimerInChannelsList" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import OfferDetailsChannels from './OfferDetailsChannels.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import { TGenre } from 'src/store/tv-channels/types';

@Component({
  components: { BadgeSequoia, ButtonDefault, OfferDetailsChannels },
})
export default class OfferDetails extends SequoiaComponent {
  get genres() {
    return selectors.tvChannels.genresSelector(this.$store);
  }

  get title() {
    return selectors.registration.trialOfferTitleSelector(this.$store, this.offer?.renderingData);
  }

  get price() {
    return selectors.registration.trialOfferPriceSelector(this.$store, this.offer?.renderingData);
  }

  get channelsCount() {
    const offerChannelCount = this.$store.authAndReg?.trial?.offerChannelCount;

    if (!offerChannelCount) {
      return '';
    }

    offerChannelCount.translation =
      this.getByLanguage(offerChannelCount.translationDict)?.replace(
        '%channelCount%',
        this.offer?.renderingData.channels.length.toString() || ''
      ) || '';
    return offerChannelCount.translation;
  }

  get vodSourcesList() {
    return selectors.registration.trialOfferVodSourcesSelector(
      this.$store,
      this.offer?.id || '',
      this.offer?.renderingData
    );
  }

  get disclaimerInChannelsList() {
    return this.getByLanguage(this.$store.authAndReg?.regAcquiring?.disclaimerInChannelsList);
  }

  get offer() {
    return selectors.registration.currentOfferSelector(this.$store);
  }

  getChannels(genre?: TGenre) {
    if (!this.offer || !genre) {
      return [];
    }

    return (
      this.offer.channels.filter(
        (channel) => channel.genres.includes(genre.id) || channel.genres.includes(genre.title)
      ) || []
    );
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/media-queries';

.offer-details {
  .title-offer {
    text-transform: uppercase;
  }

  .badge {
    margin-bottom: 8px;
  }

  .price {
    @extend %body1;

    margin-bottom: 4px;

    @include mobile {
      margin-bottom: 10px;
    }
  }

  .price-value {
    @include heading5;
  }

  .channels-count {
    @extend %body2;

    margin-bottom: 24px;
    @include mobile-and-tablet {
      margin-bottom: 16px;
    }
  }

  .vod {
    margin-bottom: 24px;
  }

  .vod {
    margin-top: -16px;
  }

  .button-buy-offer {
    @include mobile-and-tablet {
      margin-bottom: 0;
    }
  }

  .disclaimer {
    margin-top: 24px;
    color: var(--c-light-font-tertiary);
  }
}
</style>
