<template>
  <div class="profile-forms">
    <h3 v-html="getTranslationLocal('proceedTitle')" />
    <div class="profile-forms-description" v-html="getTranslationLocal('proceedSubtitle')" />
    <div class="buttons-wrapper">
      <ButtonDefault @click="clickOnBack">
        <span v-html="getTranslationLocal('proceedButton')" />
      </ButtonDefault>
      <ButtonDefault view="tertiary" @click="clickOnContinue">
        <span v-html="getTranslation('continue')" />
      </ButtonDefault>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { makePath } from 'src/utils/url';

@Component({
  components: { ButtonDefault },
})
export default class ConfirmExitModal extends SequoiaComponent {
  mounted() {
    this.gaEvent({
      category: 'acquiring',
      action: 'Показ окна "Продолжить без выбора подписки"',
      qs_qr: 'register_acquiring',
      trial_available: 'true',
    });
  }

  getTranslationLocal(key: string) {
    if (!this.$store.authAndReg?.trial) {
      return '';
    }

    const dict = this.$store.authAndReg.trial[key]?.translationDict;
    return this.getByLanguage(dict) || `{${key}`;
  }

  clickOnBack() {
    this.$emit('back');
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик "Выбрать подписку" в окне "Продолжить без выбора подписки"',
    });
  }

  clickOnContinue() {
    location.href = makePath('/');
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик "Продолжить" в окне "Продолжить без выбора подписки"',
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.buttons-wrapper {
  @include tablet {
    .button:first-child {
      flex: 1;
    }

    .button:last-child {
      flex: 0;
    }

    .button + .button {
      margin-top: 0;
      margin-left: 18px;
    }
  }
}
</style>
