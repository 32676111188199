<template>
  <div class="trial-subscription-offer" :class="[{ active }]">
    <BadgeSequoia v-if="trialDays" :text="badgeText" type="accent" />
    <div class="inner">
      <div class="name" v-text="title" />
      <div class="channels" :class="{ 'with-vod': withVod }">
        <IconSVG :svg="IconTV" class="mr-8" />
        <div>
          <div class="body1" v-text="channelsCount" />
          <div class="body2 color-light-font-secondary" v-html="getTranslation('channel_count')" />
        </div>
      </div>
      <div class="vod" :class="{ 'is-pc': isPc }">
        <template v-if="withVod">
          <IconSVG :svg="IconVod" class="mr-8" />
          <div>
            <div class="body1" v-text="vodSourcesList" />
            <div
              class="body2 color-light-font-secondary"
              v-html="getTranslation('online_cinemas')"
            />
          </div>
        </template>
      </div>

      <div class="mb-16">
        <span class="price-amount" v-text="renderingData.priceValue" />
        <span class="price-period" v-text="renderingData.priceUnit" />
      </div>

      <div class="sub-note body2 color-tertiary mb-12">
        <span v-html="getTranslation('prolongation_date_mobile')" />
        <span v-text="currentOfferTrialEndDate" />
      </div>

      <ButtonDefault
        view="primary"
        class="button-connect mb-8"
        data-cy="trials-buy"
        @click="$emit('buy-offer', id)"
      >
        <span v-html="getTranslationForAuthAndRegTrial('callToAction')" />
      </ButtonDefault>

      <div class="spacer" />

      <ButtonOfferDetails @click="onClick" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import ButtonOfferDetails from 'src/components/auth/reg-completed/trials/ButtonOfferDetails.vue';
import { TRenderingData } from 'src/store/quick-subscribe/types';
import { selectors } from 'src/store/selectors';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { dateAfterNDays } from 'src/utils/time/date';
import IconSVG from 'src/components/IconSVG.vue';
import IconVod from 'src/svg/vod.svg';
import IconTV from 'src/svg/tv.svg';
import { getDeviceFlags } from 'src/utils/platform-detector';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';

@Component({
  components: {
    BadgeSequoia,
    ButtonOfferDetails,
    ButtonDefault,
    IconSVG,
    IconTV,
  },
})
export default class TrialSubscriptionOffer extends SequoiaComponent {
  IconTV = IconTV;
  IconVod = IconVod;

  isPc = getDeviceFlags().isPc;

  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  trialDays!: number;

  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: true })
  renderingData!: TRenderingData;

  @Prop({ required: true })
  vodSources!: Array<string>;

  get prolongationDateDesktopTranslation() {
    let translation = this.getTranslation('prolongation_date') || '';
    if (this.title) {
      translation = translation.replace('%offerTitle%', this.title);
    }
    return translation;
  }

  get currentOfferTrialEndDate() {
    return dateAfterNDays(this.trialDays);
  }

  get badgeText() {
    const badgeText = selectors.registration.trialOfferBadgeTextSelector(
      this.$store,
      this.renderingData
    );
    if (!badgeText) {
      return '';
    }
    return badgeText.replace('%trialPeriod%', `${this.trialDays}`);
  }

  get title() {
    return selectors.registration.trialOfferTitleSelector(this.$store, this.renderingData);
  }

  get channelsCount() {
    return this.renderingData.channels.length;
  }

  get vodSourcesList() {
    const sources = selectors.vod.sourcesWithoutArchiveSelector(this.$store);
    const sourcesList: Array<string> = [];

    this.vodSources.forEach((sourceId) => {
      const source = sources[sourceId];

      if (source) {
        const sourceCustomTitle = this.getByLanguage(source.customTitle);
        if (sourceCustomTitle) {
          sourcesList.push(sourceCustomTitle);
        }
      }
    });
    return sourcesList.join(', ');
  }

  get withVod() {
    return this.vodSources?.length;
  }

  getTranslationForAuthAndRegTrial(key: string) {
    return actions.authAndReg.getTranslationForAuthAndRegTrial(this.$store, key);
  }

  onSelect() {
    actions.registration.setCurrentOfferId(this.$store, this.id);
    this.gaEvent({
      category: 'acquiring',
      action: 'Выбор карточки подписки',
      offer_id: this.id,
      qs_qr: 'qs',
      trial_available: 'true',
    });
  }

  onClick() {
    this.$emit('more', this.id);
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик по кнопке "Подробнее о подписке"',
      offer_id: this.id,
      qs_qr: 'qs',
      trial_available: 'true',
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

$row-margin-bottom: 8px; // space between rows
$last-row-margin-bottom: 25px; // bottom margin from the last row to the bottom border

.trial-subscription-offer {
  @extend %body2;

  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  min-width: 215px;
  min-height: 215px;
  color: var(--c-light-font-primary);

  @include mobile-and-tablet {
    min-width: 156px;
    margin-top: 6px;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    .button-connect,
    &::v-deep .more {
      z-index: var(--z-8);
    }
  }

  .inner {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    background-color: var(--c-light-100);
    border: 2px solid var(--alpha-light-5);
    border-radius: 8px;
    transition: all var(--ease-out) 0.15s;

    @include mobile-and-tablet {
      padding: 8px;
    }

    &::v-deep .more {
      margin: auto;
    }
  }

  &:hover .inner {
    background-color: var(--c-light-100);
    border-color: var(--c-light-brand);
  }

  .badge {
    position: absolute;
    top: 6px;
    left: 16px;
    z-index: var(--z-1);
    transform: translateY(-100%);

    @include mobile-and-tablet {
      left: 0;
      z-index: var(--z-0);
      justify-content: flex-start;
      width: 100%;
      height: 32px;
      padding-bottom: 5px;
      margin-bottom: -8px;
      border-radius: 4px 4px 0 0;
      transform: translateY(-100%);
    }
  }

  .name {
    @include heading5;

    margin-bottom: 16px;

    @include mobile-and-tablet {
      @include body2;

      font-weight: bold;
    }
  }

  .price {
    font-size: 2rem;
    @extend %body1;
  }

  .price-amount {
    @include heading3;

    color: var(--c-light-font-primary);

    @include mobile-and-tablet {
      @include heading6;
    }
  }

  .price-period {
    @extend %body2;

    color: var(--c-light-font-tertiary);

    @include mobile-and-tablet {
      @include caption2;
    }
  }

  .channels,
  .vod {
    display: flex;
    align-items: flex-start;

    &::v-deep .icon {
      flex-shrink: 0;
    }
  }

  .channels,
  &.active .channels.with-vod {
    padding-bottom: $last-row-margin-bottom;
  }

  .vod {
    margin-top: -$last-row-margin-bottom + 12px;
    margin-bottom: $last-row-margin-bottom;

    &.is-pc {
      min-height: 100px;
    }

    @include mobile-and-tablet {
      margin-bottom: $row-margin-bottom;
    }
  }

  .button-connect {
    width: 100%;
  }

  &.active .channels {
    padding-bottom: $row-margin-bottom;
  }

  .spacer {
    flex: 1;
  }
}
</style>
