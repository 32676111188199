<template>
  <div>
    <div ref="recaptcha" />
    <div class="color-tertiary caption2">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank" class="link nav-underlined"
        >Privacy Policy</a
      >
      and
      <a href="https://policies.google.com/terms" target="_blank" class="link nav-underlined"
        >Terms of Service</a
      >
      apply.
    </div>
  </div>
</template>

<script lang="ts">
/// <reference types="grecaptcha" />
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class RecaptchaV3 extends SequoiaComponent {
  get recaptchaId() {
    return this.$store.authAndReg.recaptchaId;
  }

  mounted() {
    if (this.recaptchaId) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaId}`;
      document.head.appendChild(script);
    }
  }

  execute() {
    window.grecaptcha.ready(() => {
      if (this.recaptchaId) {
        // noinspection JSVoidFunctionReturnValueUsed,TypeScriptValidateJSTypes
        window.grecaptcha.execute(this.recaptchaId, { action: 'submit' }).then((token) => {
          this.$emit('execute', token);
        });
      }
    });
  }
}
</script>
