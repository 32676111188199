<template>
  <div v-if="channels.length" class="genre" :class="theme">
    <SectionHeader
      class="mb-24"
      color="brand"
      :icon="IconChannelsList"
      :text="getTranslation('list_of_channels')"
    />

    <div v-if="genre" class="genre-info">
      <div class="genre-image">
        <IconSVG :svg="IconAir" :svg-path="getGenreIconPath(genre)" :size="48" />
      </div>
      <div>
        <div class="genre-name" v-html="genre.title" />
        <div class="channels-count">
          <span v-html="getTranslation('channels_declension')" />:&#32;
          <span v-text="channels.length" />
        </div>
      </div>
    </div>

    <div class="channels flex">
      <div v-for="channel in channels" :key="channel.id" class="channel">
        <LazyImage
          v-if="channel.logoUrl"
          class="channel-image"
          :src="channel.logoUrl"
          :width="114"
          :height="64"
          :with-icon="true"
        />
        <div v-else :style="{ backgroundImage: `url('${channel.image}')` }" class="channel-image" />
        <div v-if="channel.name" class="channel-name" v-html="channel.name" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import IconSVG from 'src/components/IconSVG.vue';
import IconAir from 'src/svg/genres/air.svg';
import IconChannelsList from 'src/svg/channels-list.svg';
import LazyImage from 'src/components/LazyImage.vue';
import SectionHeader from 'src/components/ui/SectionHeader.vue';
import { TGenre } from 'src/store/tv-channels/types';

@Component({
  components: { SectionHeader, LazyImage, IconSVG },
})
export default class OfferDetailsChannels extends SequoiaComponent {
  IconAir = IconAir;
  IconChannelsList = IconChannelsList;

  @Prop()
  genre?: TGenre;

  @Prop({ required: true })
  channels!: Array<any>;

  getGenreIconPath(genre: TGenre) {
    return selectors.tvChannels.genreIconSelector(this.$store, genre);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.genre {
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  .genre-info {
    display: flex;
    align-items: center;
  }

  .genre-image {
    margin-right: 14px;
  }

  .genre-name {
    @include heading5;
  }

  .channels-count {
    color: var(--c-light-font-tertiary);
    @include body2;
  }

  .channels {
    margin: 24px -8px 0;
  }

  .channel {
    width: calc(16.6% - 16px);
    margin: 0 8px 16px;

    @include mobile-and-tablet {
      width: calc(25% - 16px);
    }
  }

  .channel-image {
    width: 100%;
    padding-bottom: var(--proportion-16-9);
    overflow: hidden;
    background: no-repeat center;
    background-size: cover;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    @include tablet {
      height: 62px;
    }

    @include mobile {
      height: calc(15vw - 17px);
    }
  }

  .channel-name {
    margin-top: 8px;
    @include body2;
    @extend %truncate-after-2-lines;

    @include mobile {
      margin-top: 4px;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    .genre-image {
      color: var(--c-light-brand);
    }
  }

  &.dark {
    .genre-image {
      color: var(--c-dark-brand);
    }
  }
}
</style>
