<template>
  <div class="reg-completed">
    <RegCompletedTrialsB v-if="hasTrials && optionB" />
    <RegCompletedTrials v-else-if="hasTrials" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RegCompletedTrials from 'src/components/auth/reg-completed/trials/RegCompletedTrials.vue';
import RegCompletedTrialsB from 'src/components/auth/reg-completed/trials/RegCompletedTrialsB.vue';

@Component({
  components: {
    RegCompletedTrials,
    RegCompletedTrialsB,
  },
})
export default class RegCompleted extends Vue {
  @Prop({ required: true })
  hasTrials!: boolean;

  get optionB() {
    return this.$route.query.choice === 'b';
  }
}
</script>

<style lang="scss" scoped>
.reg-completed {
  width: 100%;
}
</style>
