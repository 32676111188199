<template>
  <div class="step-legal-links">
    <h4 v-if="title" class="h3 mb-24">
      <span v-html="title" />
    </h4>

    <div v-if="description" class="body1 mb-24" v-html="description" />

    <div
      class="mb-32 auth-scroll"
      :class="{ 'scroll-wrap': $store.authAndReg.termsOfUse.length > 4 }"
    >
      <div class="mr-8">
        <ButtonTableCell
          v-for="term in $store.authAndReg.termsOfUse"
          :key="term.translationId"
          :title="getTranslation(term.translationId)"
          :navigation-icon="IconExternalLink"
          :full-width="true"
          :is-external="true"
          :href="term.link"
        />
      </div>
    </div>

    <ButtonDefault size="medium" type="button" :full-width="true" @click="changeStep(steps.start)">
      <IconSVG :svg="IconCheckCircled" />
      <span v-html="getTranslation('i_agree_with_conditions')" />
    </ButtonDefault>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Auth from 'src/components/auth/Auth';

import ButtonTableCell from 'src/components/ui/buttons/ButtonTableCell.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconCheckCircled from 'src/svg/check-circled.svg';
import IconExternalLink from 'src/svg/external-link.svg';

@Component({
  components: {
    IconSVG,
    ButtonTableCell,
  },
})
export default class StepLegalLinks extends Auth {
  IconCheckCircled = IconCheckCircled;
  IconExternalLink = IconExternalLink;

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  description!: string;
}
</script>
