<template>
  <div class="trial-subscription-offer" :class="[{ 'ab-test': abTestCheck }]">
    <BadgeSequoia v-if="trialDays" :text="badgeText" type="accent" />
    <div class="inner" :class="[{ active }]">
      <button class="clickable-underlay" type="button" @click="onSelect" />
      <div class="name" v-html="title" />
      <div class="price bold" v-text="price" />
      <div class="channels" :class="{ 'with-vod': vodSourcesList }" v-text="channelsCount" />
      <div v-if="vodSourcesList" class="vod" v-text="vodSourcesList" />
      <div class="spacer" />
      <ButtonOfferDetails
        class="more"
        :class="{ 'hidden-mobile-and-tablet': !abTestCheck }"
        @click="onClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import ButtonOfferDetails from 'src/components/auth/reg-completed/trials/ButtonOfferDetails.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import { TRenderingData } from 'src/store/quick-subscribe/types';

@Component({
  components: {
    BadgeSequoia,
    ButtonOfferDetails,
  },
})
export default class TrialSubscriptionOffer extends SequoiaComponent {
  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  trialDays!: number;

  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: true })
  renderingData!: TRenderingData;

  get badgeText() {
    const badgeText = selectors.registration.trialOfferBadgeTextSelector(
      this.$store,
      this.renderingData
    );
    if (!badgeText) {
      return '';
    }
    return badgeText.replace('%trialPeriod%', this.trialDays.toString());
  }

  get title() {
    return selectors.registration.trialOfferTitleSelector(this.$store, this.renderingData);
  }

  get price() {
    return selectors.registration.trialOfferPriceSelector(this.$store, this.renderingData);
  }

  get channelsCount() {
    const offerChannelsCount = selectors.registration.trialOfferChannelCountsSelector(
      this.$store,
      this.renderingData
    );

    if (!offerChannelsCount) {
      return '';
    }

    return offerChannelsCount.replace(
      '%channelCount%',
      this.renderingData.channels.length.toString()
    );
  }

  get abTestCheck() {
    return this.$route?.query.opt === 'b';
  }

  get vodSourcesList() {
    return selectors.registration.trialOfferVodSourcesSelector(
      this.$store,
      this.id,
      this.renderingData
    );
  }

  onSelect() {
    actions.registration.setCurrentOfferId(this.$store, this.id);
    this.gaEvent({
      category: 'acquiring',
      action: 'Выбор карточки подписки',
      offer_id: this.id,
      qs_qr: 'qs',
      trial_available: 'true',
    });
  }

  onClick() {
    this.$emit('more');
    this.gaEvent({
      category: 'acquiring',
      action: 'Клик по кнопке "Подробнее о подписке"',
      offer_id: this.id,
      qs_qr: 'qs',
      trial_available: 'true',
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

$row-margin-bottom: 8px; // space between rows
$last-row-margin-bottom: 25px; // bottom margin from the last row to the bottom border

.trial-subscription-offer {
  @extend %body2;

  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  min-width: 215px;
  min-height: 215px;
  color: var(--c-light-font-primary);

  @include mobile-and-tablet {
    min-width: 156px;
    margin-top: 6px;
  }

  .inner {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    background-color: var(--c-light-150);
    border: 2px solid var(--alpha-light-3);
    border-radius: 4px;
    transition: all var(--ease-out) 0.15s;

    &.active {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

      .more {
        visibility: visible;
        opacity: 1;
      }
    }

    @include mobile-and-tablet {
      padding: 8px;
    }
  }

  &:hover .inner {
    border-color: var(--alpha-light-4);
  }

  .active.inner {
    background-color: var(--c-light-100);
    border-color: var(--c-light-brand);
  }

  .badge {
    position: absolute;
    top: 6px;
    left: 16px;
    z-index: var(--z-1);
    transform: translateY(-100%);

    @include mobile-and-tablet {
      left: 0;
      z-index: var(--z-0);
      justify-content: flex-start;
      width: 100%;
      height: 32px;
      padding-bottom: 5px;
      margin-bottom: -8px;
      border-radius: 4px 4px 0 0;
      transform: translateY(-100%);
    }
  }

  .name {
    @include heading5;

    margin-bottom: 16px;

    @include mobile-and-tablet {
      @include body2;

      min-height: 40px;
      font-weight: bold;
    }
  }

  .price {
    margin-bottom: $row-margin-bottom;
    @extend %body1;
  }

  .price-amount {
    @include heading3;

    color: var(--c-light-font-primary);

    @include mobile-and-tablet {
      @include heading6;
    }
  }

  .price-period {
    @extend %body2;

    color: var(--c-light-font-tertiary);

    @include mobile-and-tablet {
      @include caption2;
    }
  }

  .channels,
  &.active .channels.with-vod {
    padding-bottom: $last-row-margin-bottom;
  }

  .vod {
    margin-top: -$last-row-margin-bottom + 4px;
    margin-bottom: $last-row-margin-bottom;

    @include mobile-and-tablet {
      margin-bottom: $row-margin-bottom;
    }
  }

  &.active .channels {
    padding-bottom: $row-margin-bottom;
  }

  .spacer {
    flex: 1;
  }

  .more {
    z-index: var(--z-1);
    visibility: hidden;
    opacity: 0;
  }

  // AB Test
  &.ab-test {
    @include mobile {
      min-height: 128px;
      margin-bottom: 32px;

      .inner {
        padding: 16px 12px;
      }

      .badge {
        top: 6px;
        left: 16px;
        z-index: var(--z-1);
        display: block;
        width: auto;
        padding-bottom: 4px;
        margin-bottom: 0;
        border-radius: 2px;
        transform: translateY(-100%);
      }

      .price {
        position: absolute;
        top: 16px;
        right: 12px;
        z-index: var(--z-0);
      }

      .channels {
        margin-bottom: 16px;
      }

      .spacer {
        display: none;
      }
    }
  }
}
</style>
