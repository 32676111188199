<template>
  <div class="page-wrapper scroll-layout-wrap">
    <div
      class="layout-registration-completed"
      :class="[{ 'with-background': withBackground }, { 'ab-test': abTestCheck }]"
    >
      <div class="logo-wrap container-1440">
        <img v-if="logo" :src="logo" class="logo" title="Смотрёшка" alt="Смотрёшка" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class RegCompletedLayout extends SequoiaComponent {
  @Prop({ required: true })
  withBackground!: boolean;

  get abTestCheck() {
    return this.$route.query.opt === 'b';
  }

  get logo() {
    const logo = this.getByLanguage(
      this.$store.theme === 'light'
        ? this.$store.siteConfig?.logo?.light
        : this.$store.siteConfig?.logo?.dark
    );
    return logo?.url;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.page-wrapper {
  flex-grow: 1;
}

.layout-registration-completed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 32px 64px 48px;

  @include tablet {
    padding: 30px 48px 80px;
  }

  @include mobile {
    padding: 24px 24px 32px;

    &.ab-test {
      padding-bottom: 0;
    }
  }

  @include mobile-and-tablet {
    .logo-wrap {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &.with-background {
    background: url('/public/images/backgrounds/reg.png') no-repeat right top;
    background-size: contain;

    @include mobile-and-tablet {
      background: none;
    }
  }

  .logo {
    width: 189px;
    height: 40px;
  }
}
</style>
