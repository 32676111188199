<template>
  <div class="step-auth">
    <h4 v-if="title" class="h3 mb-24 promocode-title">
      <span v-html="title" />
      <BadgeSequoia
        v-if="$store.authAndReg.promoCodeValid"
        class="promocode-badge"
        :text="$store.authAndReg.canonicalValue"
        :icon="IconGift"
        type="accent"
      />
    </h4>

    <form autocomplete="off" novalidate @submit.prevent="auth">
      <InputText
        v-if="isPhoneMode && $store.authAndReg.phoneMask.phoneCode"
        v-model="phone"
        input-category="floating"
        name="phone"
        type="tel"
        size="medium"
        required
        data-cy="phone"
        :label="getTranslation('phone_number_reg')"
        :status="statusForPhoneInput"
        :message="messageForPhoneInput"
        @focus="gaEventOnceAuth({ action: 'Фокус в поле телефон' })"
        @keypress="onInput"
        @onCompletePhoneCallback="onCompletePhoneCallback"
        @onIncompletePhoneCallback="onIncompletePhoneCallback"
      />

      <InputText
        v-else
        v-model="phone"
        name="phone"
        input-category="floating"
        size="medium"
        required
        data-cy="phone"
        :status="statusForLoginInput"
        :message="messageForLoginInput"
        :label="getTranslation('login_reg')"
        @focus="gaEventOnceAuth({ action: 'Фокус в поле логин' })"
      />

      <InputText
        v-model="$store.authAndReg.passwordAuth"
        input-category="floating"
        size="medium"
        icon="visibility"
        required
        data-cy="password"
        :minlength="passwordMinLength"
        :maxlength="passwordMaxLength"
        :label="getTranslation('password_reg')"
        :message="getTranslation('password_min_symbols_warning')"
        @focus="gaEventOnceAuth({ action: 'Фокус в поле пароль' })"
      />

      <div v-if="forgotPassword" class="forgot-password mt-16 text-right description">
        <router-link
          to="/reset"
          tag="a"
          class="link nav-underlined mb-16"
          @click.native="onClickOnForgotPassword"
          v-html="getTranslation('forgot_password_reg')"
        />
      </div>

      <div
        v-if="!isMts && signInContentBlock"
        class="content-block mt-16"
        v-html="getByLanguage($store.authAndReg.contentBlock)"
      />

      <ErrorSequoia :messages="errorMessages" data-cy="login" class="ml-16 mt-16" />

      <ButtonDefault
        class="mt-16"
        data-cy="submit"
        size="medium"
        :full-width="true"
        :is-loading="isLoading"
        :disabled="isSubmitPasswordButtonDisabled"
      >
        <span v-html="buttonText" />
      </ButtonDefault>
    </form>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Auth from 'src/components/auth/Auth';
import { cleanupPhone } from 'src/utils/phone';
import { CORRECT_PHONE_LENGTH } from 'src/constants';

@Component
export default class StepAuth extends Auth {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  buttonText!: string;

  @Prop({ default: false })
  signInContentBlock!: boolean;

  @Prop({ default: false })
  forgotPassword!: boolean;

  mounted() {
    this.$store.authAndReg.gaNameComponent = 'auth';
  }

  get isSubmitPasswordButtonDisabled() {
    return (
      this.isLoading ||
      (this.isPhoneMode &&
        this.$store.authAndReg?.phoneMask.use &&
        (this.incompletePhoneInitialFlag ||
          this.incompletePhone ||
          cleanupPhone(this.phone).length < CORRECT_PHONE_LENGTH)) ||
      (!this.isPhoneMode && !this.phone) ||
      this.$store.authAndReg.passwordAuth.length < this.passwordMinLength
    );
  }

  onClickOnForgotPassword() {
    this.gaEventAuth({
      action: 'Клик "Забыли пароль"',
    });

    if (this.isPhoneMode && this.phone) {
      this.phone = cleanupPhone(this.phone);
      this.$store.authAndReg.passwordAuth = '';
    }
  }
}
</script>
