<template>
  <div v-if="!initialLoading" class="registration">
    <StepStart
      v-if="currentStep === steps.start"
      :title="getTranslation($store.authAndReg.isTypeReg === 'reg' ? 'registration' : 'enter')"
      :text-below-button="textBelowButtonText"
      :use-vercont-server="useVercont"
      :input-phone-message="phoneIncompleteOrUnsupportedTranslation"
      @clickAuthWithPassword="handleAuthWithPassword"
      @autoLogin="autoLoginForUztel"
    />

    <StepAuthMts
      v-if="currentStep === steps.authMts"
      :title="getTranslation('enter_reg')"
      :sign-in-content-block="true"
      :button-text="getTranslation('signin')"
      @clickButton="handleSmsAuth"
      @clickButton2="handleAuthWithPassword"
    />

    <StepCheckCode
      v-if="currentStep === steps.checkCode"
      :title="getTranslation('enter')"
      :with-resend-code="!isMts"
      @clickButton="sendCheckedCode"
    />

    <StepSimPassword
      v-if="currentStep === steps.simPassword"
      :title="getTranslation($store.authAndReg.isTypeReg === 'reg' ? 'registration' : 'enter')"
      @sendSimPassword="sendSimPassword"
    />

    <StepCaptcha v-if="currentStep === steps.captcha" :use-vercont-server="useVercont" />

    <StepMultipleLogins
      v-if="currentStep === steps.multipleLogins"
      :title="getTranslation(`welcome_back_${$store.authAndReg.wlAppId}`)"
      :show-name="true"
      :description="getTranslation('select_login')"
      @selectLogin="selectLogin"
    />

    <StepOther
      v-if="currentStep === steps.phoneFoundBeforeReset"
      :title="getTranslation(`welcome_back_${$store.authAndReg.wlAppId}`)"
      :login="formatPhone(phone)"
      @clickLogin="clickEnter"
    >
      <template v-slot:description>
        <span
          v-html="getTranslation('phone_already_registered').replace('%phone%', formatPhone(phone))"
        />
      </template>
    </StepOther>

    <StepPromoCode
      v-if="currentStep === steps.promoCode"
      :title="getTranslation('auth_promo_code_title')"
      :description="getTranslation('auth_promo_code_description')"
      :button-text="getTranslation('auth_promo_code_btn')"
    />

    <StepOther
      v-if="currentStep === steps.promoCodeNotFound"
      :title="getTranslation('auth_promo_code_error_title')"
      :button-text="getTranslation('enter_another')"
      :button-text2="getTranslation('auth_promo_code_next_without_code')"
      :error="true"
      :notify-text="getTranslation('auth_promo_code_not_code')"
      icon-name="gift"
      :result-step="true"
      :width-marker="true"
      @clickButton="changeStep(steps.promoCode)"
      @clickButton2="continueWithoutPromoCode"
    >
      <template v-slot:description>
        <span
          v-html="
            $store.authAndReg.promoCodeResultText ||
            getTranslation('auth_promo_code_notFound_description').replace(
              '%code%',
              $store.authAndReg.promoCode.toUpperCase()
            )
          "
        />
      </template>
    </StepOther>

    <StepOther
      v-if="currentStep === steps.promoCodeUserExist"
      :title="getTranslation('auth_promo_code_error_title')"
      :error="true"
      icon-name="gift"
      :result-step="true"
      :width-marker="true"
      :button-text="getTranslation('auth_promo_code_success_btn')"
      @clickButton="clickEnter"
    >
      <template v-slot:description>
        <span v-html="getTranslation('auth_promo_code_userexist_text')" />
      </template>
    </StepOther>

    <StepOther
      v-if="currentStep === steps.promoCodeSuccess"
      :title="getTranslation('auth_promo_code_success_title')"
      :button-text="getTranslation('auth_promo_code_success_btn')"
      icon-name="gift"
      :result-step="true"
      @clickButton="clickEnter"
    >
      <template v-slot:description>
        <span
          v-html="
            $store.authAndReg.promoCodeResultText || getTranslation('auth_promo_code_success_text')
          "
        />
      </template>
    </StepOther>

    <StepOther
      v-if="currentStep === steps.autoLoginFailed"
      :title="getTranslation('something_went_wrong')"
      :error="true"
      :result-step="true"
      icon-name="warning"
      :button-text="getTranslation('try_again_1')"
      :button-text2="getTranslation('signin_another_way')"
      @clickButton="autoLoginForUztel"
      @clickButton2="flushProcess"
    >
      <template v-slot:description>
        <span v-html="getTranslation('auto_login_error_text')" />
      </template>
    </StepOther>

    <StepOther
      v-if="currentStep === steps.finish"
      :title="getTranslation('congratulations')"
      :button-text="getTranslation('watch_reg')"
      icon-name="check"
      :result-step="true"
      @clickButton="clickEnter"
    >
      <template v-slot:description>
        <span v-html="getTranslation('phone_number_reg')" />
        &nbsp;<b class="phone" v-html="formatPhone(phone)" />
        <span v-html="getTranslation('is_registered')" />
        <span v-html="getTranslation('start_subscription')" />
      </template>
    </StepOther>

    <StepOther
      v-if="currentStep === steps.outdated"
      :title="getTranslation($store.authAndReg.isTypeReg ? 'registration' : 'enter')"
      :button-text="getTranslation('try_again_2')"
      @clickButton="flushProcess"
    >
      <template v-slot:description>
        <span v-html="getTranslation('session_time_out')" />
        <br />
        <span v-html="getTranslation('try_again_1')" />
      </template>
    </StepOther>

    <StepOther
      v-if="currentStep === steps.phoneUnsupported"
      :title="getTranslation($store.authAndReg.isTypeReg ? 'registration' : 'enter')"
      :button-text="getTranslation('try_again_2')"
      @clickButton="flushProcess"
    >
      <template v-slot:description>
        <span v-html="getTranslation('phone_unsupported_reg_kt')" />
      </template>
    </StepOther>

    <StepOther
      v-if="currentStep === steps.error"
      :title="getTranslation('something_went_wrong')"
      :button-text="getTranslation('try_again_2')"
      :error="true"
      icon-name="warning"
      :result-step="true"
      @clickButton="flushProcess"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Auth from 'src/components/auth/Auth';
import { getEnvVars } from 'src/config';

import StepStart from 'src/components/auth/steps/StepStart.vue';
import StepCheckCode from 'src/components/auth/steps/StepCheckCode.vue';
import StepMultipleLogins from 'src/components/auth/steps/StepMultipleLogins.vue';
import StepOther from 'src/components/auth/steps/StepOther.vue';
import StepCaptcha from 'src/components/auth/steps/StepCaptcha.vue';
import StepSimPassword from 'src/components/auth/steps/StepMegafonSimPassword.vue';
import StepPromoCode from './steps/StepPromoCode.vue';
import StepAuthMts from 'src/components/auth/steps/StepAuthMts.vue';

import {
  AUTH_STEPS,
  CONTACT_VERIFICATION_ERROR_CODE,
  CONTACT_VERIFICATION_OUTDATED_ERROR_CODE,
  HTTP_CODES,
  REGISTRATION_SMS_CODE_LENGTH,
} from 'src/constants';
import { actions } from 'src/store/actions';
import { sleep, wlDetector } from 'src/utils';
import { cleanupPhone } from 'src/utils/phone';
import * as api from 'src/api';
import { localStore } from 'src/utils/storage';
import logger from 'src/utils/logger';

const log = logger('auth-and-reg-by-sms');

@Component({
  components: {
    StepAuthMts,
    StepStart,
    StepCheckCode,
    StepMultipleLogins,
    StepOther,
    StepCaptcha,
    StepPromoCode,
    StepSimPassword,
  },
})
export default class AuthAndRegBySmsWL extends Auth {
  simAuthSuccess = false;
  authWidthPasswordAndPromoCodes = false;

  get phoneCleaned() {
    return cleanupPhone(this.phone, this.usePlusInPhoneMethods.auth);
  }

  get useVercont() {
    return this.isMegacom || this.isUztel || this.isMegafon || this.isIntersv;
  }

  get login() {
    return this.$store.authAndReg.logins?.length
      ? this.$store.authAndReg.logins[0].login
      : this.phoneCleaned;
  }

  get textBelowButtonText() {
    return this.getTranslation(
      this.$store.authAndReg.isTypeReg === 'reg'
        ? this.$store.authAndReg.textBelowRegButtonId
        : this.$store.authAndReg.textBelowLoginButtonId
    );
  }

  get phoneIncompleteOrUnsupportedTranslation() {
    return this.getTranslation(
      this.incompletePhone
        ? 'incomplete_phone_reg'
        : this.phoneUnsupported
        ? this.$store.authAndReg.phoneUnsupportedMessage
        : ''
    );
  }

  @Watch('checkCode')
  onCheckCode(val: string) {
    if (this.isLoading || this.isMts) {
      return;
    }

    if (val.length === REGISTRATION_SMS_CODE_LENGTH) {
      this.sendCheckedCode();
    }
  }

  created() {
    this.$store.authAndReg.wlAppId = getEnvVars().wlAppId;
    this.$store.authAndReg.isTypeReg = this.$route.query.type === 'reg' ? 'reg' : '';
  }

  async mounted() {
    this.$store.authAndReg.gaNameComponent = 'forgot_password';
    this.$store.authAndReg.verificationComponentName = 'authentication';
    this.$store.isPhoneMode = true;
    this.$store.authAndReg.isAuthWithPassword = false;

    await this.checkUseRecaptcha();

    this.$nextTick(() => {
      this.$store.authAndReg.captchaRefreshValue++;
    });

    if (this.$route.query.phone) {
      this.phone = '+7' + this.$route.query.phone;
      this.$store.incompletePhoneInitialFlag = false;
    }
    this.initialLoading = false;
  }

  beforeDestroy() {
    this.flushProcess(false);
  }

  async sendCheckedCode() {
    this.checkCode = this.checkCode.replace(/\D/g, '');

    this.isLoading = true;
    this.errors.length = 0;
    this.errorMessages.length = 0;
    this.$_verification_clearTimeout(this.timeoutIdUntilSessionTokenOutdated);
    actions.registration
      .verify(this.$store, {
        data: {
          type: 'phone',
          contacts: this.phoneCleaned,
          code: this.checkCode,
        },
      })
      .then(() => {
        this.afterCheckCodeIsOk();

        this.gaEventAuth({ action: 'Сабмит кода из смс' });
        this.yaMetrikaGoal('Sms_code_submit');
      })
      .catch((err) => {
        this.isLoading = false;
        if (err.code === HTTP_CODES.NOT_FOUND) {
          this.changeStep(AUTH_STEPS.outdated);
        } else if (err.code === HTTP_CODES.BAD_REQUEST) {
          this.showErrorPage(err);
        } else {
          this.errorMessages.push(err.message);
          throw err;
        }

        this.gaEventAuth({ action: 'Сабмит кода из смс', error_text: err.message });
      });
  }

  async selectLogin(login: string) {
    if (this.$store.authAndReg.promoCodeValid) {
      return await this.promoCodeRegister();
    } else {
      await this.signIn(login);
    }
  }

  handleSmsAuth() {
    this.$store.authAndReg.isSmsAuth = true;
    this.changeStep(this.steps.start);
  }

  handleAuthWithPassword() {
    this.$store.authAndReg.isAuthWithPassword = true;
    this.$nextTick(() => {
      this.$store.isPhoneMode = true;
    });
  }

  async afterCheckCodeIsOk() {
    await this.getLogins();
    if (this.$store.authAndReg.promoCodeValid && this.$store.authAndReg.logins.length <= 1) {
      return await this.promoCodeRegister();
    }

    try {
      this.$_verification_setTimeout(this.changeStep, AUTH_STEPS.outdated);

      await this.getLogins();
      switch (this.$store.authAndReg.logins.length) {
        case 0:
          await this.finishRegistration();
          this.gaEventAuth({ action: 'Показ страницы "К сожалению, номер не зарегистрирован"' });
          break;
        case 1:
          this.isLoading = false;
          if (this.isAuth) {
            await this.signIn(this.login);
          } else {
            this.changeStep(AUTH_STEPS.phoneFoundBeforeReset);
          }
          break;
        default:
          this.isLoading = false;
          this.changeStep(AUTH_STEPS.multipleLogins);
          break;
      }
    } catch (err) {
      if (err?.code === CONTACT_VERIFICATION_OUTDATED_ERROR_CODE) {
        this.changeStep(AUTH_STEPS.outdated);
      } else if (err?.code === CONTACT_VERIFICATION_ERROR_CODE) {
        this.showErrorPage(err);
      } else {
        throw err;
      }
    }
  }

  async getLogins() {
    await actions.authAndReg.getAvailableLogins(this.$store);
    this.$store.authAndReg.logins = this.$store.availableLogins?.options || [];
  }

  async promoCodeRegister() {
    try {
      const { message } = await api.auth.registerWithPromoCodeMobile({
        params: { 'contacts-session-token': this.$store.verificationResult.sessionToken },
        data: { promocode: this.$store.authAndReg.promoCodeId },
      });
      this.$store.authAndReg.promoCodeResultText = message;
      this.changeStep(this.steps.promoCodeSuccess);
    } catch (err) {
      const nextStep =
        err?.code === HTTP_CODES.BAD_REQUEST
          ? this.steps.promoCodeUserExist
          : this.steps.promoCodeNotFound;
      this.changeStep(nextStep);
    }
  }

  continueWithoutPromoCode() {
    this.$store.authAndReg.promoCode = '';
    this.$store.authAndReg.promoCodeValid = false;
    this.changeStep(this.steps.start);
  }

  async autoLoginForUztel() {
    this.isLoading = true;
    try {
      await actions.authAndReg.uztelAuth(this.$store, this.$ssrContext?.cookiesToSet);
      localStore.set('showLoginSuccessNotification', true);
      actions.authAndReg.showLoginSuccessNotification(this.$store);
      this.gaEvent({ category: 'auth', action: 'Вход по ip' });
      await this.redirectAfterLogin();
    } catch (err) {
      log.error(err);
      this.errorText = err.message || '';
      this.changeStep(this.steps.autoLoginFailed);
    } finally {
      this.isLoading = false;
    }
  }

  async sendSimPassword() {
    this.isLoading = true;
    try {
      await actions.authAndReg.login(
        this.$store,
        this.phoneCleaned,
        this.$store.authAndReg.passwordAuth,
        this.$ssrContext?.cookiesToSet
      );

      if (this.$store.authAndReg.promoCodeValid) {
        this.authWidthPasswordAndPromoCodes = true;
        this.changeStep(AUTH_STEPS.promoCodeUserExist);
      } else {
        await this.redirectAfterLogin();
      }
    } catch (err) {
      if (err.code === HTTP_CODES.FORBIDDEN) {
        await this.registerSimPassword();
      }
    }
  }

  async registerSimPassword() {
    const data: {
      login: string;
      password: string;
      promocode?: string;
    } = {
      login: cleanupPhone(this.phone, this.usePlusInPhoneMethods.registration),
      password: this.$store.authAndReg.passwordAuth,
    };

    if (this.$store.authAndReg.promoCodeValid && this.$store.authAndReg.logins.length <= 1) {
      data.promocode = this.$store.authAndReg.promoCodeId;
    }

    try {
      await api.auth.registerSimPassword({
        data,
      });

      this.simAuthSuccess = true;
      const nextStep = this.$store.authAndReg.promoCodeValid
        ? AUTH_STEPS.promoCodeSuccess
        : AUTH_STEPS.finish;
      this.changeStep(nextStep);
    } catch (err) {
      this.handleError(err);
      this.$store.authAndReg.passwordAuth = '';
    }
  }

  async finishRegistration() {
    try {
      await actions.registration.registerMobileWithToken(this.$store, {
        params: this.prepareFinishRegistration(),
      });

      await sleep(3000);
      await this.getLogins();
      await this.signIn(this.login, false);
      this.isLoading = false;
      this.changeStep(AUTH_STEPS.finish);
    } catch (err) {
      if (err?.code === CONTACT_VERIFICATION_OUTDATED_ERROR_CODE) {
        this.changeStep(AUTH_STEPS.outdated);
      } else if (err?.code === CONTACT_VERIFICATION_ERROR_CODE) {
        this.showErrorPage(err);
      } else if (err?.code === HTTP_CODES.BAD_REQUEST) {
        this.changeStep(AUTH_STEPS.phoneUnsupported);
      } else {
        throw err;
      }
      this.isLoading = false;
    }
  }

  async clickEnter() {
    if (this.authWidthPasswordAndPromoCodes) {
      await this.redirectAfterLogin();
    } else if (this.simAuthSuccess) {
      await this.auth();
    } else {
      await this.getLogins();
      await this.signIn(this.login);
    }
  }

  async signIn(login: string, withRedirect = true) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    if (!login) {
      if (this.$store.authAndReg.logins.length) {
        login = this.$store.authAndReg.logins[0].login;
        this.gaEventAuth({ action: 'Клик по кнопке "Войти"' });
      } else {
        login = this.phoneCleaned;
        this.gaEventAuth({ action: 'Клик по кнопке "Смотреть ТВ" после ' });
      }
      this.yaMetrikaGoal('Reg_completion');
    }

    try {
      await actions.authAndReg.loginWithVerifiedContacts(
        this.$store,
        login,
        this.$ssrContext?.cookiesToSet
      );
      this.gaEventAuth({ action: 'Клик "Войти без смены пароля"' });

      if (wlDetector().isUztel) {
        try {
          await actions.payment.loadPaymentSubscriptionsV2(this.$store);
        } catch (err) {
          log.error('onLoadPaymentSubscriptions', err);
        }
      }

      if (withRedirect) {
        this.goToMainPageWithReloadAndTimeout();
      }
    } catch (err) {
      this.showErrorPage(err);
    }
  }

  getMetaInfo() {
    return {
      title: this.getTranslation('login_meta_title'),
    };
  }
}
</script>
