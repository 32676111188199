<template>
  <AuthLayout>
    <div class="auth-header">
      <LogoSequoia />
    </div>

    <div class="auth-content">
      <AuthSlider />

      <div class="auth-forms">
        <div class="auth-forms-inner">
          <ResetPasswordSM v-if="isSmotreshka || isN3" />
          <ResetPasswordWL v-else />
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { wlDetector } from 'src/utils';
import LogoSequoia from 'src/components/auth/parts/LogoSequoia.vue';
import AuthLayout from 'src/layouts/AuthLayout.vue';
import AuthSlider from 'src/components/auth/parts/AuthSlider.vue';
import ResetPasswordSM from 'src/components/auth/ResetPasswordSM.vue';
import ResetPasswordWL from 'src/components/auth/ResetPasswordWL.vue';
import { SequoiaPage } from 'src/mixins';

@Component({
  components: { AuthLayout, ResetPasswordSM, ResetPasswordWL, LogoSequoia, AuthSlider },
})
export default class ResetPasswordPage extends SequoiaPage {
  get isSmotreshka() {
    return wlDetector().isSmotreshka;
  }
  get isN3() {
    return wlDetector().isN3;
  }
  getMetaInfo() {
    return {
      title: this.getTranslation('reset_meta_title'),
      meta: [
        {
          name: 'description',
          content: this.getTranslation('reset_meta_description'),
        },
        {
          name: 'keywords',
          content: this.getTranslation('reset_meta_keywords'),
        },
      ],
    };
  }
}
</script>
