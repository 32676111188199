<template>
  <div class="form-container">
    <form v-if="!resetSuccess" novalidate autocomplete="off" @submit.prevent="handleResetPassword">
      <div v-html="resestPasswordContentBlock" />

      <InputText
        v-if="$store.authAndReg.phoneMask.use"
        v-model="email"
        input-category="floating"
        name="phone"
        type="tel"
        size="medium"
        required
        data-cy="phone"
        :label="getTranslation('phone_number_reg')"
        :status="statusForLoginInput"
        :message="messageForLoginInput"
        @keypress="onInput"
        @onCompletePhoneCallback="onCompletePhoneCallback"
        @onIncompletePhoneCallback="onIncompletePhoneCallback"
      />

      <InputText
        v-else
        v-model="email"
        input-category="floating"
        size="medium"
        :status="errorLogin ? 'error' : undefined"
        :message="errorLogin ? errorLogin.message : ''"
        :label="getTranslation('login')"
      />

      <InputText
        v-model="captchaValue"
        input-category="floating"
        size="medium"
        required
        class="mb-16"
        :with-captcha="true"
        :maxlength="6"
        :status="statusForCaptchaInput"
        :message="messageForCaptchaInput"
        :label="getTranslation('code_reg')"
        :description="getTranslation('enter_digits_left')"
      />

      <div v-if="errorMessages.length" class="errors-container mt-8 mb-16">
        <div v-for="error in errorMessages" class="error" v-text="error" />
      </div>

      <ButtonDefault
        class="mt-8"
        size="medium"
        :full-width="true"
        :is-loading="isLoading"
        :disabled="isSubmitCaptchaButtonDisabled"
      >
        <span v-html="getTranslation('change_password')" />
      </ButtonDefault>
    </form>

    <SuccessMessage v-if="resetSuccess" :message="getTranslation('restore_password_success')" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';
import SuccessMessage from 'src/components/auth/parts/SuccessMessage.vue';
import { cleanupPhone } from 'src/utils/phone';
import { REGISTRATION_CAPTCHA_LENGTH } from 'src/constants';
import * as api from 'src/api';

@Component({
  components: {
    SuccessMessage,
  },
})
export default class ResetPasswordWL extends Auth {
  email = '';
  signUpCheckboxes: Array<any> = [];
  resetSuccess = false;

  get isSubmitCaptchaButtonDisabled() {
    if (this.captchaValue.length < REGISTRATION_CAPTCHA_LENGTH || this.isLoading) {
      return true;
    }

    if (this.$store.isPhoneMode) {
      return !!this.incompletePhone;
    } else {
      return !this.email.length;
    }
  }

  get resestPasswordContentBlock() {
    return this.getByLanguage(this.$store.authAndReg.restorePassword?.contentBlock);
  }

  mounted() {
    this.$store.isPhoneMode = false;
    if (this.$store.authAndReg.registration) {
      this.signUpCheckboxes = this.$store.authAndReg.registration?.checkboxes?.map((checkbox) => ({
        value: false,
        content: this.getByLanguage(checkbox.contentBlock),
      }));
    }
  }

  async handleResetPassword() {
    const byPhone = this.$store.authAndReg?.phoneMask?.use;
    const data = {
      [`${byPhone ? 'phone' : 'login'}`]: byPhone
        ? cleanupPhone(this.email, this.usePlusInPhoneMethods.restorePassword)
        : this.email,
      captcha: this.captchaValue,
      cookies: this.$store.authAndReg.cookieCaptcha,
    };

    try {
      await api.auth.restorePassword({ data });
      this.resetSuccess = true;
    } catch (err) {
      this.$store.authAndReg.captchaRefreshValue++;
      this.captchaValue = '';
      this.handleError(err);
    }
  }
}
</script>
