<template>
  <div class="step-recommend-provider">
    <h4 v-if="title" class="h3 mb-24">
      <span v-html="title" />
    </h4>

    <div v-if="description" class="body1 mb-24" v-html="description" />

    <div class="button-width-description mb-16 is-megafon">
      <ButtonDefault
        size="medium"
        type="button"
        :full-width="true"
        :is-loading="isLoading"
        @click="$emit('clickButton')"
      >
        <span v-html="getTranslation('provider_button_megafon')" />
      </ButtonDefault>
      <div
        class="button-description mt-8 body2"
        v-html="getTranslation('provider_caption_megafon')"
      />
    </div>

    <div class="button-width-description">
      <ButtonDefault size="medium" type="button" :full-width="true" @click="nextStep">
        <span v-html="getTranslation('signup')" />
      </ButtonDefault>
      <div class="button-description mt-8 body2" v-html="getTranslation('use_bank_card_to_pay')" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Auth from 'src/components/auth/Auth';
import { AUTH_STEPS } from 'src/constants';

@Component
export default class StepRecommendProvider extends Auth {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  description!: string;

  get providerName() {
    return (
      this.$store.providers.find(
        (provider) => provider.id === this.$store.authAndReg.recommendProvider
      )?.name || ''
    );
  }

  nextStep() {
    this.changeStep(AUTH_STEPS.skipProvider);
    this.$store.authAndReg.recommendProvider = '';
  }
}
</script>

<style scoped lang="scss">
.button-width-description {
  width: 100%;
  padding: 16px;
  background-color: var(--alpha-light-2);
  border-radius: 12px;

  &.is-megafon {
    &::v-deep {
      .button {
        color: var(--c-megafon-button-text);
        background-color: var(--c-megafon-bg);

        &:hover,
        &:focus {
          background-color: var(--c-megafon-bg-hover);
        }
      }

      .loader-spinner.light .svg .path {
        stroke: var(--c-megafon-bg);
      }
    }
  }

  .button-description {
    color: var(--c-light-font-secondary);
    text-align: center;
  }
}
</style>
