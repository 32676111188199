<template>
  <div class="reg-completed-trials" :class="theme">
    <h1 class="title with-side-padding" v-html="title" />

    <div
      class="description body1 with-side-padding"
      v-html="getTranslationForAuthAndRegTrial('registrationSubtitle')"
    />

    <div class="background-wrapper pb-64">
      <svg class="background" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon class="triangle" points="0,100 0,60 100,8 100,100" />
      </svg>

      <div v-if="isPc" class="subscription-offers-desktop">
        <ContentSlider type="adaptive" class="slider" :class="{ centered: offers.length < 4 }">
          <TrialSubscriptionOfferB
            v-for="offer in offers"
            :id="offer.id"
            :key="offer.id"
            class="slide col-desktop-3 col-tablet-2 col-mobile-2"
            :active="offer.id === currentOfferId"
            :trial-days="offer.trialDays"
            :rendering-data="offer.renderingData"
            :vod-sources="offer.vodSources"
            @more="onShowMore"
            @buyOffer="onBuyOffer"
          />
        </ContentSlider>
      </div>

      <div v-else class="subscription-offers flex">
        <TrialSubscriptionOfferB
          v-for="offer in offers"
          :id="offer.id"
          :key="offer.id"
          class="subscription-offer"
          :active="offer.id === currentOfferId"
          :trial-days="offer.trialDays"
          :rendering-data="offer.renderingData"
          :vod-sources="offer.vodSources"
          @more="onShowMore"
          @buyOffer="onBuyOffer"
        />
      </div>
    </div>

    <div class="container-1440">
      <button
        type="button"
        class="link nav-hidden continue-without-subscription body2 with-side-padding"
        @click="showExitConfirmation = true"
      >
        <span class="inner" v-html="getTranslationForAuthAndRegTrial('proceedNoSubscription')" />
      </button>

      <div
        class="rules color-tertiary body2 with-side-padding"
        v-html="getTranslationForAuthAndRegTrial('disclaimer')"
      />
    </div>

    <ModalSequoia
      v-if="showOfferDetails"
      type="fixed"
      size="large"
      class="modal-details"
      :title="offer.title"
      @close="showOfferDetails = false"
    >
      <OfferDetails class="with-side-and-bottom-padding" @buyOffer="onBuyOffer" />
    </ModalSequoia>

    <ModalSequoia
      v-if="showExitConfirmation"
      type="fixed"
      size="small"
      :with-close-button="true"
      @close="showExitConfirmation = false"
    >
      <ConfirmExitModal class="with-side-and-bottom-padding" @back="showExitConfirmation = false" />
    </ModalSequoia>

    <ModalSequoia v-if="showGiftModal" type="fixed" @close="onCloseGiftModal">
      <GiftModal @close="onCloseGiftModal" />
    </ModalSequoia>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import ButtonOfferDetails from 'src/components/auth/reg-completed/trials/ButtonOfferDetails.vue';
import { currentOfferIdSelector } from 'src/store/registration/selectors';
import TrialSubscriptionOffers from 'src/components/auth/reg-completed/trials/trials-list/TrialSubscriptionOffers.vue';
import TrialSubscriptionOfferB from 'src/components/auth/reg-completed/trials/trials-list/TrialSubscriptionOfferB.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import ConfirmExitModal from 'src/components/auth/reg-completed/trials/ConfirmExitModal.vue';
import IconSVG from 'src/components/IconSVG.vue';
import OfferDetails from 'src/components/auth/reg-completed/acquiring/offer-details/OfferDetails.vue';
import GiftModal from 'src/components/auth/reg-completed/trials/GiftModal.vue';
import NotificationSimple from 'src/components/ui/notifications/NotificationSimple.vue';
import logger from 'src/utils/logger';
import { getDeviceFlags } from 'src/utils/platform-detector';
import ContentSlider from 'src/components/ui/ContentSlider.vue';

const log = logger('reg-completed-trials');

@Component({
  components: {
    ButtonOfferDetails,
    NotificationSimple,
    GiftModal,
    IconSVG,
    ButtonDefault,
    TrialSubscriptionOfferB,
    TrialSubscriptionOffers,
    ModalSequoia,
    ConfirmExitModal,
    OfferDetails,
    ContentSlider,
  },
})
export default class RegCompletedTrials extends SequoiaComponent {
  showOfferDetails = false;
  showExitConfirmation = false;
  showGiftModal = false;
  isPc = getDeviceFlags().isPc;

  get offers() {
    return selectors.registration.offersSelector(this.$store);
  }

  get currentOfferId() {
    return selectors.registration.currentOfferIdSelector(this.$store);
  }

  get maxTrialDays() {
    let maxTrialDays = 0;
    this.offers
      .filter((offer) => offer.trialAvailable)
      .forEach((offer) => {
        maxTrialDays = Math.max(offer.trialDays, maxTrialDays);
      });
    return maxTrialDays;
  }

  get hasGiftModal() {
    const channels = this.offer?.channels || [];
    const trialChannels = this.offer?.trialChannels || [];
    return trialChannels.length > channels.length;
  }

  get confirmationUrl() {
    return selectors.registration.confirmationUrlSelector(this.$store);
  }

  get offer() {
    return selectors.registration.currentOfferSelector(this.$store);
  }

  get title() {
    const title = this.getTranslationForAuthAndRegTrial('registrationTitle') || '';
    return title
      .replace('%maxTrialNum%', this.maxTrialDays.toString())
      .replace('%maxTrialDays%', this.getDaysWord(this.maxTrialDays));
  }

  get createSubscriptionError() {
    return this.$store.registration.createSubscriptionError;
  }

  async mounted() {
    actions.registration.setCurrentOfferId(this.$store, '');
    this.gaEvent({ category: 'acquiring', action: 'Показ диалога оплаты' });
  }

  getTranslationForAuthAndRegTrial(key: string) {
    return actions.authAndReg.getTranslationForAuthAndRegTrial(this.$store, key);
  }

  onShowMore(id: string) {
    actions.registration.setCurrentOfferId(this.$store, id);
    this.showOfferDetails = true;
  }

  async onBuyOffer(id: string) {
    if (id) {
      actions.registration.setCurrentOfferId(this.$store, id);
    }
    this.showOfferDetails = false;
    await actions.registration.createSubscription(this.$store);
    if (this.createSubscriptionError) {
      actions.registration.disableTrials(this.$store);
    }
    if (!this.confirmationUrl) {
      log.error('no confirmation url');
      return;
    }
    if (this.hasGiftModal) {
      this.showGiftModal = true;
    } else {
      window.location.href = this.confirmationUrl;
    }

    this.gaEvent({
      category: 'acquiring',
      action: 'Клик по кнопке "Подключить"',
      offer_id: currentOfferIdSelector(this.$store),
    });
  }

  onCloseGiftModal() {
    this.showGiftModal = false;
    window.location.href = this.confirmationUrl;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/link';

.reg-completed-trials {
  width: 100%;

  .title {
    @extend %heading2;

    max-width: 800px;
    margin: 48px 0 24px;

    @include mobile-and-tablet {
      @include heading3;
    }

    @include tablet {
      margin-top: 40px;
      margin-bottom: 16px;
      text-align: left;
    }

    @include mobile {
      margin: 28px 0;
      text-align: left;
    }
  }

  .description {
    max-width: 800px;
    margin-bottom: 48px;

    @include mobile-and-tablet {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid var(--alpha-light-3);
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  .choose-offer {
    padding-bottom: 48px;
    margin-bottom: 0;
    color: var(--c-light-font-secondary);

    @include mobile-and-tablet {
      padding-bottom: 24px;
    }

    @include mobile {
      padding-bottom: 32px;
    }
  }

  .offers {
    margin-bottom: 64px;
  }

  .button-and-note-wrap {
    text-align: center;
    @include mobile-and-tablet {
      text-align: left;
    }
  }

  .button-connect-wrap {
    @include mobile-and-tablet {
      display: flex;
      align-items: center;
    }

    @include tablet {
      margin-bottom: 32px;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  .button-connect {
    width: 256px;
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 0;
    }

    @include mobile {
      width: 100%;
    }
  }

  .sub-note {
    padding-bottom: 32px;

    @include mobile {
      padding-bottom: 24px;
    }

    &.ab-test {
      display: none;
    }
  }

  .background-wrapper {
    position: relative;

    @include mobile-and-tablet {
      margin-bottom: 0;
    }

    .subscription-offers {
      .subscription-offer {
        width: 320px;
        margin-right: 16px;
        margin-bottom: 40px;

        @include mobile {
          width: 100%;
        }
      }
    }

    .subscription-offers-desktop {
      max-width: 1440px;

      .slider {
        &::v-deep .slider-items {
          padding-top: 20px;

          @include mobile-and-tablet {
            display: flex;
          }

          @include tablet {
            padding-top: 50px;
          }

          @include mobile {
            padding-top: 30px;
          }
        }

        &.centered::v-deep .slider-items {
          justify-content: center;

          &::after {
            display: none;

            @include mobile-and-tablet {
              display: block;
            }
          }

          @include mobile-and-tablet {
            justify-content: flex-start;
          }
        }
      }

      .slide {
        @include devices-with-touch {
          display: inline-flex !important;
        }
      }
    }
  }

  .button-offer-more {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 0;
      margin-left: 48px;
    }

    @include mobile {
      margin-bottom: 32px;
    }
  }

  .background {
    position: absolute;
    bottom: 0;
    left: -64px;
    width: calc(100% + 128px);
    height: 100%;

    @include mobile-and-tablet {
      display: none;
    }
  }

  .triangle {
    fill: rgba(0, 0, 0, 0.02);
  }

  .continue-without-subscription {
    display: inline-flex;
    align-items: center;
    margin-top: 26px;
    margin-bottom: 18px;
    border-bottom: 0;

    .inner {
      margin-right: 4px;
      border-bottom: 1px solid transparent;
    }

    @include devices-with-hover {
      &:hover {
        .inner {
          border-bottom-color: currentColor;
        }

        .icon {
          transform: translateX(8px);
        }
      }
    }

    &:active {
      color: var(--c-light-font-secondary);
    }
  }

  .rules {
    max-width: 680px;

    @include mobile-and-tablet {
      max-width: 100%;
    }

    &::v-deep p {
      margin-bottom: 6px;
    }

    &::v-deep a {
      @extend %light-link-nav-underlined;
    }
  }

  .modal-details::v-deep .dialog > .title {
    @extend %heading2;

    padding-bottom: 0;
  }

  // AB Test
  &.ab-test {
    .trial-subscription-offer {
      display: none;
      @include mobile {
        display: block;
      }
    }

    @include mobile {
      .trial-subscription-offers {
        display: none;
      }

      .choose-offer {
        display: block;
        text-align: left;
      }

      .button-and-note-wrap {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: var(--z-8);
        width: 100%;
        padding: 16px 24px 24px;
        background-color: var(--c-light-100);
        border-radius: 8px 8px 0 0;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
      }

      .button-connect {
        margin-bottom: 8px;
      }

      .button-offer-more {
        display: none;
      }

      .sub-note {
        &.ab-test {
          display: block;
          padding-bottom: 0;
        }

        &:not(.ab-test) {
          display: none;
        }
      }

      .rules {
        padding-bottom: 164px;
      }
    }
  }

  &.dark {
    .rules {
      &::v-deep a {
        @extend %dark-link-nav-underlined;
      }
    }
  }
}
</style>
