<template>
  <div class="step-start">
    <h4 v-if="title" class="h3 mb-24 promocode-title">
      <span v-html="title" />
      <BadgeSequoia
        v-if="$store.authAndReg.promoCodeValid"
        class="promocode-badge"
        :text="$store.authAndReg.canonicalValue"
        :icon="IconGift"
        type="accent"
      />
    </h4>

    <div v-if="description" class="body1 mb-32" v-html="description" />

    <div v-if="showLoginButton" class="body1 mb-32">
      <span v-html="getTranslation('already_account')" />&#32;
      <router-link
        to="/login"
        class="link nav-underlined"
        @click.native="gaEventAuth({ action: 'Клик по кнопке «Войти»' })"
        v-html="getTranslation('signin_reg')"
      />
    </div>

    <div v-if="changePhoneMode" class="login-type mb-32">
      <SelectorSingleDesktop
        :items="itemsLoginType"
        :selected="currentLoginType"
        type="secondary"
        size="medium"
        @select="$emit('changePhoneMode')"
      />
    </div>

    <form novalidate autocomplete="off" @submit.prevent="start">
      <InputText
        v-if="isPhoneMode"
        v-model="phone"
        input-category="floating"
        name="phone"
        type="tel"
        size="medium"
        required
        data-cy="phone"
        :label="getTranslation('phone_number_reg')"
        :status="incompletePhone || phoneUnsupported ? 'error' : undefined"
        :message="inputPhoneMessage"
        @focus="onFocus"
        @onCompletePhoneCallback="onCompletePhoneCallback"
        @onIncompletePhoneCallback="onIncompletePhoneCallback"
      />

      <InputText
        v-else
        v-model="email"
        input-category="floating"
        size="medium"
        required
        :placeholder="getTranslation('login_reg')"
        :label="getTranslation('login_reg')"
        :status="statusForLoginInput"
        :message="messageForLoginInput"
      />

      <InputText
        v-if="showCaptcha"
        v-model="captchaValue"
        input-category="floating"
        size="medium"
        required
        :with-captcha="true"
        data-cy="captcha"
        :use-vercont-server="useVercontServer"
        :maxlength="6"
        :label="getTranslation('code_reg')"
        :description="getTranslation('enter_digits_left')"
        :message="messageForCaptchaInput"
        :status="statusForCaptchaInput"
      />

      <InputText
        v-if="!$store.isPhoneMode && isAuth"
        id="step-start-password"
        v-model="$store.authAndReg.passwordAuth"
        input-category="floating"
        size="medium"
        icon="visibility"
        required
        data-cy="password"
        :minlength="passwordMinLength"
        :maxlength="passwordMaxLength"
        :label="getTranslation('password_reg')"
        :message="getTranslation('password_min_symbols_warning')"
        @focus="gaEventOnceAuth({ action: 'Фокус в поле пароль' })"
      />

      <div v-if="isAuth && !isPhoneMode" class="forgot-password mt-16 text-right description">
        <router-link
          to="/reset"
          tag="a"
          class="link nav-underlined mb-16"
          @click.native="onClickOnForgotPassword"
          v-html="getTranslation('forgot_password_reg')"
        />
      </div>

      <ErrorSequoia :messages="errorMessages" data-cy="captcha" class="ml-16 mt-16" />

      <ButtonDefault
        class="mt-16"
        size="medium"
        :full-width="true"
        data-cy="submit"
        :is-loading="isLoading && !isSubmitCaptchaButtonDisabled"
        :disabled="isSubmitCaptchaButtonDisabled"
      >
        <span v-html="getTranslation('next_reg')" />
      </ButtonDefault>

      <ButtonDefault
        v-if="isMts"
        class="mt-16"
        size="medium"
        type="button"
        :full-width="true"
        view="secondary"
        @click="$emit('clickAuthWithPassword')"
      >
        <span v-html="getTranslation('mts_login_with_password')" />
      </ButtonDefault>

      <div class="auto-login flex-center">
        <template v-if="isUztel">
          <div class="delimiter-line mb-16 mt-16">
            <span class="delimiter-line-text" v-html="getTranslation('or')" />
          </div>
          <ButtonDefault
            type="button"
            view="secondary"
            size="medium"
            :full-width="true"
            :is-loading="isLoading && isSubmitCaptchaButtonDisabled"
            @click="$emit('autoLogin')"
          >
            <span v-html="getTranslation('auto_login_btn')" />
          </ButtonDefault>
        </template>

        <a
          v-if="shouldShowPromoCodeButton"
          class="link link-icon nav-icon-colored mt-32 mb-16"
          @click="changeStep(steps.promoCode)"
        >
          <IconSVG :svg="IconGift" />
          <span class="text" v-html="getTranslation('i_have_promo_code')" />
        </a>
      </div>

      <div v-if="isAuth" class="description register-link-container text-center mt-32 px-0">
        <router-link
          :to="isKt ? '?type=reg' : '/reg'"
          tag="a"
          class="link nav-underlined"
          @click.native="gaEventAuth({ action: 'Клик “Регистрация”' })"
          v-html="getTranslation('registration_reg')"
        />
      </div>

      <div v-if="textBelowButton" class="mt-16" v-html="textBelowButton" />

      <div v-if="signInContentBlock" class="content-block mt-16" v-html="signInContentBlock" />

      <RecaptchaV3
        v-if="useRecaptcha"
        ref="recaptcha"
        class="mt-16"
        @execute="onRecaptchaExecute"
      />
    </form>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { CORRECT_PHONE_LENGTH, REGISTRATION_CAPTCHA_LENGTH } from 'src/constants';
import RecaptchaV3 from 'src/components/auth/parts/RecaptchaV3.vue';
import Auth from 'src/components/auth/Auth';
import SelectorSingleDesktop from 'src/components/ui/selector-single/SelectorSingleDesktop.vue';
import { Prop } from 'vue-property-decorator';
import { cleanupPhone } from 'src/utils/phone';

@Component({
  components: {
    RecaptchaV3,
    SelectorSingleDesktop,
  },
})
export default class StepStart extends Auth {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  description!: string;

  @Prop({ default: '' })
  inputPhoneMessage!: string;

  @Prop({ default: false })
  changePhoneMode!: boolean;

  @Prop({ default: false })
  hasAccount!: boolean;

  @Prop({ default: false })
  hasEula!: boolean;

  @Prop({ default: '' })
  textBelowButton!: string;

  @Prop({ default: false })
  useVercontServer?: boolean;

  @Prop({ default: true })
  showPromoCodeButton!: boolean;

  get correctPhoneLength() {
    return this.isUztel ? 9 : this.isMts ? 13 : CORRECT_PHONE_LENGTH;
  }

  get showLoginButton() {
    return this.hasAccount && !this.$store.authAndReg.promoCodeValid;
  }

  get showCaptcha() {
    return (
      (!this.useRecaptcha && this.$store.isPhoneMode) || (!this.isAuth && !this.$store.isPhoneMode)
    );
  }

  get isSubmitCaptchaButtonDisabled() {
    if (this.isLoading) {
      return false;
    }

    if (!this.isPhoneMode) {
      return !this.email || this.isAuth
        ? this.$store.authAndReg.passwordAuth.length < this.passwordMinLength
        : this.captchaValue.length < REGISTRATION_CAPTCHA_LENGTH;
    }

    return (
      cleanupPhone(this.phone).length < this.correctPhoneLength ||
      this.incompletePhoneInitialFlag ||
      this.incompletePhone ||
      this.phoneUnsupported ||
      (!this.useRecaptcha && this.captchaValue.length < REGISTRATION_CAPTCHA_LENGTH) ||
      (!this.useRecaptcha && this.isKt && this.captchaValue.length < REGISTRATION_CAPTCHA_LENGTH)
    );
  }

  get shouldShowPromoCodeButton() {
    return (
      !this.$store.authAndReg.promoCodeValid &&
      this.$store.authAndReg?.usePromoCodes &&
      this.showPromoCodeButton
    );
  }

  get signInContentBlock() {
    return this.getByLanguage(this.$store.authAndReg?.contentBlock);
  }

  onFocus() {
    this.$emit('focusOnScroll');
    this.gaEventOnceAuth({ action: 'Фокус в поле телефон' });
  }

  mounted() {
    if (this.$store.authAndReg.authPhoneNotFound) {
      this.phone = this.$store.authAndReg.authPhoneNotFound;
    }

    this.captchaValue = '';
  }
}
</script>
