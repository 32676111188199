<template>
  <div class="gift-modal profile-forms">
    <ImageWithHash src="/public/images/trials/gift.png" alt="" class="image" />
    <h3 v-html="getTranslationLocal('giftTitle')" />
    <div v-if="giftDescription" class="profile-forms-description" v-html="giftDescription" />
    <div class="buttons-wrapper">
      <ButtonDefault @click="$emit('close')">
        <span v-html="getTranslationLocal('giftButton')" />
      </ButtonDefault>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { selectors } from 'src/store/selectors';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ButtonDefault, ImageWithHash },
})
export default class GiftModal extends SequoiaComponent {
  mounted() {
    this.gaEvent({
      category: 'acquiring',
      action: 'Показ окна "Подарок от смотрешки"',
      offer_id: this.currentOffer?.id,
      qs_qr: 'qs',
    });
  }

  get giftDescription() {
    let giftDescription = this.getTranslationLocal('giftSubtitle');
    if (this.currentOffer) {
      giftDescription = giftDescription
        .replace('%channelCountTrial%', this.currentOffer.trialChannels.length.toString())
        .replace('%channelCount%', this.currentOffer.channels.length.toString());
    }
    return giftDescription;
  }

  get currentOffer() {
    return selectors.registration.currentOfferSelector(this.$store);
  }

  getTranslationLocal(key: string) {
    if (!this.$store.authAndReg?.trial) {
      return '';
    }
    const dict = this.$store.authAndReg.trial[key]?.translationDict;
    return this.getByLanguage(dict) || `{${key}`;
  }
}
</script>

<style lang="scss" scoped>
.gift-modal {
  padding: 0 48px 48px;

  .image {
    width: 72px;
    margin-bottom: 16px;
  }
}
</style>
