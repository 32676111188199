<template>
  <AuthLayout>
    <div class="auth-header">
      <LogoSequoia />
    </div>
    <div class="auth-content">
      <AuthSlider />
      <div class="auth-forms">
        <div class="auth-forms-inner">
          <div class="registration restore-password">
            <h4 class="h3 mb-24" v-html="getTranslation('pwd_recovery')" />

            <form
              v-if="!passwordSuccessfullyChanged"
              novalidate
              autocomplete="off"
              @submit.prevent="restorePassword"
            >
              <InputText
                v-model="new_password"
                input-category="floating"
                size="medium"
                icon="visibility"
                class="mb-16"
                :tab-index-for-button="-1"
                :label="getTranslation('password_reg')"
                :minlength="passwordMinLength"
                :maxlength="passwordMaxLength"
                :description="getTranslation('password_min_symbols_warning')"
              />

              <InputText
                v-model="new_password_repeated"
                input-category="floating"
                size="medium"
                icon="visibility"
                class="mb-24"
                :tab-index-for-button="-1"
                :label="getTranslation('confirm_password')"
                :minlength="passwordMinLength"
                :maxlength="passwordMaxLength"
              />

              <ErrorSequoia :messages="errorMessages" data-cy="password" class="ml-16 mb-16" />

              <ButtonDefault size="medium" :disabled="isSubmitPasswordButtonDisabled">
                <span v-html="getTranslation('edit_reg')" />
              </ButtonDefault>
            </form>

            <template v-if="passwordSuccessfullyChanged">
              <div class="flex mb-24 mt-64 mt-mobile-48 mt-tablet-32 color-success">
                <IconSVG :svg="IconCheckCircled" class="mr-8" />
                <span class="message" v-html="getTranslation('pwd_changed')" />
              </div>
              <ButtonDefault href="/login" @click="pushLoginRoute">
                <span v-html="getTranslation('login_new_pwd')" />
              </ButtonDefault>
            </template>
          </div>
        </div>
      </div>
    </div>
    <LoaderSpinner v-if="isLoading" />
  </AuthLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';
import AuthLayout from 'src/layouts/AuthLayout.vue';
import { selectors } from 'src/store/selectors';
import LogoSequoia from 'src/components/auth/parts/LogoSequoia.vue';
import * as api from 'src/api';
import logger from 'src/utils/logger';
import IconCheckCircled from 'src/svg/check-circled.svg';
import IconSVG from 'src/components/IconSVG.vue';
import AuthSlider from 'src/components/auth/parts/AuthSlider.vue';

const log = logger('restore-password');

@Component({
  components: {
    AuthLayout,
    LogoSequoia,
    IconSVG,
    AuthSlider,
  },
})
export default class RestorePassword extends Auth {
  IconCheckCircled = IconCheckCircled;

  new_password = '';
  new_password_repeated = '';
  passwordSuccessfullyChanged = false;

  get passwordMinLength() {
    return selectors.common.authAndRegPasswordMinSelector(this.$store);
  }

  get passwordMaxLength() {
    return selectors.common.authAndRegPasswordMaxSelector(this.$store);
  }

  get isSubmitPasswordButtonDisabled() {
    return (
      !this.$route.query.id ||
      !this.$route.query.accountID ||
      !this.$route.query.code ||
      this.isLoading ||
      this.new_password.length < this.passwordMinLength ||
      this.new_password_repeated.length < this.passwordMinLength
    );
  }

  pushLoginRoute() {
    this.$router.push('/login');
  }

  async restorePassword() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    try {
      await api.auth.restorePasswordByLink(
        {
          id: this.$route.query.id as string,
          accountId: this.$route.query.accountID as string,
        },
        {
          data: {
            new_password: this.new_password,
            new_password_repeated: this.new_password_repeated,
            code: this.$route.query.code,
          },
        }
      );
      this.passwordSuccessfullyChanged = true;
      this.isLoading = false;
    } catch (err) {
      log.error(err);
      this.handleError(err);
    }
  }
}
</script>
