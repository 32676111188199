<template>
  <div class="step-megafon-sim-password">
    <h4 v-if="title" class="h3 mb-24 promocode-title">
      <span v-html="title" />
      <BadgeSequoia
        v-if="$store.authAndReg.promoCodeValid"
        class="promocode-badge"
        :text="$store.authAndReg.canonicalValue"
        :icon="IconGift"
        type="accent"
      />
    </h4>

    <div class="description mb-24">
      <span v-html="description" />
    </div>

    <div class="accordion-find-password mb-24">
      <Accordion component-name="megafon_sim_password" />
    </div>

    <form novalidate autocomplete="off" @submit.prevent="$emit('sendSimPassword')">
      <InputText
        v-model="$store.authAndReg.passwordAuth"
        input-category="floating"
        size="medium"
        icon="visibility"
        required
        data-cy="password"
        :minlength="passwordMinLength"
        :maxlength="passwordMaxLength"
        :label="getTranslation('password_reg')"
        :message="errorText"
        :status="errorText ? 'error' : undefined"
        @focus="gaEventOnceAuth({ action: 'password_input' })"
      />

      <ErrorSequoia :messages="errorMessages" data-cy="login" class="ml-16 mt-16" />

      <ButtonDefault
        class="mt-16"
        data-cy="submit"
        size="medium"
        :full-width="true"
        :is-loading="isLoading"
        :disabled="isSubmitPasswordButtonDisabled"
      >
        <span v-html="getTranslation('next_reg')" />
      </ButtonDefault>
    </form>
  </div>
</template>

<script lang="ts">
import Auth from '../Auth';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AccordionItem from 'src/components/ui/AccordionItem.vue';
import Accordion from 'src/components/cms/Accordion.vue';
import { formatPhone } from 'src/utils/phone';

@Component({
  components: {
    AccordionItem,
    Accordion,
  },
})
export default class StepMegafonSimPassword extends Auth {
  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  buttonText!: string;

  get isSubmitPasswordButtonDisabled() {
    return this.isLoading || this.$store.authAndReg.passwordAuth.length < this.passwordMinLength;
  }

  get description() {
    return `${this.getTranslation('enter_password_from_number')} ${formatPhone(this.phone)}`;
  }

  mounted() {
    this.$store.authAndReg.passwordAuth = '';
    this.$store.authAndReg.phoneTechnicalSim = false;
  }
}
</script>
