<template>
  <div class="megafon-sim">
    <div class="sim-image">
      <ImageSlider component-name="megafon_sim" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ImageSlider from 'src/components/cms/ImageSlider.vue';

@Component({
  components: {
    ImageSlider,
  },
})
export default class MegafonSim extends SequoiaComponent {}
</script>
<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.sim-image {
  position: relative;

  img {
    width: 100%;
  }
}

@include mobile-and-tablet {
  .sim-image {
    width: 296px;
    height: 150px;
    overflow: hidden;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) 75%, transparent 100%);

    &::v-deep img {
      position: absolute;
      bottom: 0;
      z-index: var(--z-1);
    }
  }
}
</style>
