<template>
  <div :class="theme">
    <div v-if="!initialLoading" class="registration">
      <StepLegalLinks
        v-if="currentStep === steps.legalLinks"
        :title="getTranslation('term_of_use_title')"
        :description="getTranslation('term_of_use_description')"
      />

      <StepStart
        v-if="currentStep === steps.start"
        :title="stepTitle"
        :description="getTranslation('enter_phone_for_reg')"
        :input-phone-message="getTranslation('incomplete_phone_reg')"
        :has-account="!$store.authAndReg.promoCodeValid && !isAuth"
        :has-eula="true"
        :use-vercont-server="true"
        :show-promo-code-button="!isAuth"
        :change-phone-mode="isAuth"
        @focusOnScroll="focusOnScroll"
        @changePhoneMode="handleSwitch"
      />

      <StepPromoCode
        v-if="currentStep === steps.promoCode"
        :title="getTranslation('auth_promo_code_title')"
        :description="getTranslation('auth_promo_code_description')"
        :button-text="getTranslation('auth_promo_code_btn')"
      />

      <StepSimPassword
        v-if="currentStep === steps.simPassword"
        :title="getTranslation('password_login')"
        @sendSimPassword="sendSimPassword"
      />

      <StepOther
        v-if="currentStep === steps.promoCodeNotFound"
        :title="getTranslation('auth_promo_code_error_title')"
        :button-text="getTranslation('enter_another')"
        :button-text2="getTranslation('auth_promo_code_next_without_code')"
        :error="true"
        icon-name="gift"
        :result-step="true"
        :width-marker="true"
        @clickButton="changeStep(steps.promoCode)"
        @clickButton2="continueWithoutPromoCode"
      >
        <template v-slot:description>
          <span v-if="!errorText" v-html="notFoundDescription" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.promoCodeUserExist"
        :title="getTranslation('auth_promo_code_userexist_title')"
        :error="true"
        icon-name="gift"
        :width-marker="true"
        :button-text="getTranslation('signin_reg')"
        :button-text2="getTranslation('auth_promo_code_userexist_btn2')"
        :result-step="true"
        @clickButton="clickEnter"
        @clickButton2="continueWithNewPhone"
      >
        <template v-slot:description>
          <span
            v-html="
              getTranslation('auth_promo_code_userexist_text').replace(
                '%phone%',
                formatPhone(phone)
              )
            "
          />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.promoCodeSuccess"
        :title="getTranslation('auth_promo_code_success_title')"
        :button-text="getTranslation('auth_promo_code_success_btn')"
        icon-name="gift"
        :result-step="true"
        @clickButton="clickEnterPromo"
      >
        <template v-slot:description>
          <div class="provider-phone body1">
            <span v-html="getTranslation('phone_number_reg')" />: <b v-text="formatPhone(phone)" />
          </div>
          <div v-if="providerName" class="body1">
            <span v-html="getTranslation('internet_provider')" />:
            <b v-text="providerName" />
          </div>
        </template>
        <template v-slot:afterButton>
          <span v-html="getTranslation('benefits_promo_code')" />&#32;
          <b v-html="$store.authAndReg.canonicalValue" />&#32;
          <div class="color-tertiary" v-html="$store.authAndReg.promoCodeResultText" />
        </template>
      </StepOther>

      <StepCheckCode
        v-if="currentStep === steps.checkCode"
        :title="getTranslation('enter_code_reg')"
        :description="getTranslation('channels_vod')"
      />

      <StepOther
        v-if="currentStep === steps.skipProvider"
        :title="getTranslation('registration_reg')"
        :button-text="getTranslation('continue')"
        :button-text2="getTranslation('select_provider')"
        @clickButton="finishRegistration"
        @clickButton2="changeStep(steps.provider)"
      >
        <template v-slot:description>
          <span v-html="getTranslation('select_provider_text')" />
        </template>
      </StepOther>

      <StepCaptcha v-if="currentStep === steps.captcha" :use-vercont-server="true" />

      <StepRecommendProvider
        v-if="currentStep === steps.recommendProvider"
        :title="getTranslation('registration_reg')"
        :description="getTranslation('select_reg_method')"
        @clickButton="finishRegistration"
      />

      <StepProvider
        v-if="currentStep === steps.provider"
        @finishRegistration="changeStep(steps.confirmProvider)"
      />

      <StepOther
        v-if="currentStep === steps.confirmProvider"
        :title="getTranslation('confirm_provider')"
        :button-text="getTranslation('thats_right')"
        :button-text2="getTranslation('change_provider')"
        icon-name="check"
        :result-step="true"
        @clickButton="finishRegistration"
        @clickButton2="changeStep(steps.provider)"
      >
        <template v-slot:description>
          <div class="provider-phone body1">
            <span v-html="getTranslation('phone_number_reg')" />: <b v-text="formatPhone(phone)" />
          </div>
          <div class="body1">
            <span v-html="getTranslation('region')" />: <b v-text="regionName" />
          </div>
          <div class="body1">
            <span v-html="getTranslation('internet_provider')" />:
            <b v-html="providerName || getTranslation('not_on_the_list')" />
          </div>
        </template>
      </StepOther>

      <StepMultipleLogins
        v-if="currentStep === steps.multipleLogins"
        :title="getTranslation('welcome_back')"
        :description="getTranslation('select_login')"
        @selectLogin="selectLogin"
      />

      <StepOther
        v-if="currentStep === steps.error"
        :title="getTranslation('something_went_wrong')"
        :button-text="getTranslation('try_again_2')"
        :error="true"
        icon-name="warning"
        :result-step="true"
        @clickButton="flushProcess"
      />

      <StepOther
        v-if="currentStep === steps.outdated"
        :title="getTranslation('registration_reg')"
        :button-text="getTranslation('try_again_2')"
        @clickButton="flushProcess"
      >
        <template v-slot:description>
          <span v-html="getTranslation('session_time_out')" />
          <br />
          <span v-html="getTranslation('try_again_1')" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.phoneFoundBeforeReset"
        :title="getTranslation('welcome_back')"
        :login="formatPhone(phone)"
        @clickLogin="clickEnter"
      >
        <template v-slot:description>
          <span
            v-html="
              getTranslation('phone_already_registered').replace('%phone%', formatPhone(phone))
            "
          />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.phoneFoundAfterReset"
        :title="getTranslation('welcome_back')"
        :button-text="getTranslation('signin_reg')"
        @clickButton="clickEnter"
      >
        <template v-slot:description>
          <span v-html="getTranslation('login_pwd_sent_number')" />
          <b v-text="formatPhone(phone)" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.phoneNotFound"
        :title="getTranslation('account_not_found')"
        :result-step="true"
        icon-name="warning"
        :button-text="getTranslation('signup')"
        :button-text2="getTranslation('signin_another_way')"
        @clickButton="regAfterPhoneNotFound"
        @clickButton2="changeStep(steps.start)"
      >
        <template v-slot:description>
          <span
            v-html="getTranslation('phone_not_registered').replace('%phone%', formatPhone(phone))"
          />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.finish"
        :title="getTranslation('thank_you_for_registering')"
        :before-title="getTranslation('three_one')"
        :button-text="getTranslation('watch_reg')"
        icon-name="check"
        :result-step="true"
        @clickButton="clickEnter"
      >
        <template v-slot:description>
          <div class="provider-phone body1">
            <span v-html="getTranslation('phone_number_reg')" />:
            <b v-text="formatPhone(phone || $store.authAndReg.authPhoneNotFound)" />
          </div>
          <div v-if="$store.account?.provider?.provider.name" class="body1">
            <span v-html="getTranslation('internet_provider')" />:
            {{ $store.account?.provider?.provider.name }}
          </div>
        </template>
        <template v-slot:afterButton>
          {{ startSubscriptionTranslation }}
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.retryTrialStatus"
        :title="getTranslation('something_went_wrong')"
        :show-button-go-to-home-page="true"
        :button-text="getTranslation('try_again_2')"
        @clickButton="retryTrialStatus"
      >
        <template v-slot:description>
          <span v-html="getTranslation('error_occurred')" />&#32;<span
            v-html="getTranslation('try_again')"
          />
        </template>
      </StepOther>
    </div>

    <LoaderSpinner v-if="initialLoading" />
    <div class="go-to-ask-link" v-html="getByLanguage($store.authAndReg.reportProblem)" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Auth from 'src/components/auth/Auth';
import * as api from 'src/api';

import StepStart from 'src/components/auth/steps/StepStart.vue';
import StepCheckCode from 'src/components/auth/steps/StepCheckCode.vue';
import StepCaptcha from 'src/components/auth/steps/StepCaptcha.vue';
import StepProvider from 'src/components/auth/steps/StepProvider.vue';
import StepMultipleLogins from 'src/components/auth/steps/StepMultipleLogins.vue';
import StepOther from 'src/components/auth/steps/StepOther.vue';
import StepPromoCode from 'src/components/auth/steps/StepPromoCode.vue';
import StepSimPassword from 'src/components/auth/steps/StepMegafonSimPassword.vue';
import StepLegalLinks from 'src/components/auth/steps/StepLegalLinks.vue';
import StepRecommendProvider from 'src/components/auth/steps/StepRecommendProvider.vue';

import {
  AUTH_STEPS,
  CONTACT_VERIFICATION_ERROR_CODE,
  CONTACT_VERIFICATION_OUTDATED_ERROR_CODE,
  GET_TRIAL_STATUS_ERROR_CODE,
  HTTP_CODES,
  PHONE_INFO_VERIFICATION_METHODS,
  REGISTRATION_SMS_CODE_LENGTH,
  REGISTRATION_TIMEOUT_AFTER_SIGN_IN,
} from 'src/constants';
import { actions } from 'src/store/actions';
import { sleep } from 'src/utils';
import { selectors } from 'src/store/selectors';
import { cookieStore, sessionStore } from 'src/utils/storage';
import logger from 'src/utils/logger';
import { cleanupPhone } from 'src/utils/phone';
import {
  autoTrialDurationSelector,
  wasAutoTrialCreatedSelector,
} from 'src/store/registration/selectors';
import AuthLayout from 'src/layouts/AuthLayout.vue';

const log = logger('registration-sm');

@Component({
  components: {
    StepStart,
    StepCheckCode,
    StepCaptcha,
    StepProvider,
    StepMultipleLogins,
    StepOther,
    StepPromoCode,
    StepLegalLinks,
    StepRecommendProvider,
    StepSimPassword,
  },
})
export default class RegistrationSM extends Auth {
  @Prop()
  scrollBlock!: AuthLayout;

  @Watch('checkCode')
  onCheckCode(val: string) {
    if (this.isLoading) {
      return;
    }

    this.checkCode = this.checkCode.replace(/\D/g, '');

    if (val.length === REGISTRATION_SMS_CODE_LENGTH) {
      this.isLoading = true;
      this.errors.length = 0;
      this.errorMessages.length = 0;
      this.$_verification_clearTimeout(this.timeoutIdUntilSessionTokenOutdated);

      actions.registration
        .verify(this.$store, {
          data: {
            type: 'phone',
            contacts: cleanupPhone(this.phone, this.usePlusInPhoneMethods.registration),
            code: this.checkCode,
          },
        })
        .then(() => {
          this.afterCheckCodeIsOk();
          this.gaEventAuth({ action: 'Сабмит кода из смс' });
          this.yaMetrikaGoal('Sms_code_submit');
        })
        .catch((err: { code: number; message: string }) => {
          this.gaEventAuth({ action: 'Сабмит кода из смс', error_text: err.message });

          this.isLoading = false;
          if (err.code === HTTP_CODES.NOT_FOUND) {
            this.changeStep(AUTH_STEPS.outdated);
          } else if (err.code === HTTP_CODES.BAD_REQUEST) {
            this.showErrorPage(err);
          } else {
            this.errorMessages.push(err.message);
            throw err;
          }
        });
    }
  }

  get providerId() {
    return this.$store.authAndReg.register.providerIdFromUrl
      ? this.$store.authAndReg.register.providerId
      : this.$store.authAndReg.recommendProvider || this.$store.authAndReg.register.providerId;
  }

  get providerName() {
    return (
      this.$store.providers.find(
        (provider) => provider.id === this.$store.authAndReg.register.providerId
      )?.name || this.$store.authAndReg.register.providerName
    );
  }

  get stepTitle() {
    return this.getTranslation(this.isAuth ? 'enter_reg' : 'registration_reg');
  }

  get regionId() {
    return this.$store.authAndReg.register.regionId;
  }

  get regionName() {
    return this.$store.regions.find(
      (region) => region.id === this.$store.authAndReg.register.regionId
    )?.name;
  }

  get notFoundDescription() {
    return this.$store.authAndReg.promoCodeResultText
      ? this.$store.authAndReg.promoCodeResultText
      : this.getTranslation('auth_promo_code_notFound_description').replace(
          '%code%',
          this.$store.authAndReg.promoCode.toUpperCase()
        );
  }

  get startSubscriptionTranslation() {
    return wasAutoTrialCreatedSelector(this.$store) && autoTrialDurationSelector(this.$store)
      ? this.successWithTrialTranslation
      : this.getTranslation('start_subscription');
  }

  get successWithTrialTranslation() {
    return this.getTranslation('phone_register_success_with_trial').replace(
      '%trialPeriod%',
      autoTrialDurationSelector(this.$store).toFixed(2)
    );
  }

  async mounted() {
    let step = this.isAuth ? AUTH_STEPS.start : AUTH_STEPS.legalLinks;
    if (this.$store.authAndReg.authPhoneNotFound) {
      step = this.$store.authAndReg.recommendProvider
        ? AUTH_STEPS.recommendProvider
        : AUTH_STEPS.skipProvider;
    }
    this.changeStep(step);

    this.isLoading = true;
    this.$store.authAndReg.gaNameComponent = this.isAuth ? 'auth' : 'registration';
    this.$store.authAndReg.verificationComponentName = this.isAuth
      ? 'authentication'
      : 'registration';
    this.phone = '';

    this.$store.isPhoneMode = true;
    this.$store.authAndReg.register.providerIdFromUrl = this.$route.query?.provider as string;
    this.$store.regions = (await api.auth.getRegions())?.regions || [];
    this.$store.providers = (await api.auth.getProviders())?.providers || [];
    await this.checkUseRecaptcha();
    this.initialLoading = false;
    this.isLoading = false;
  }

  beforeDestroy() {
    this.flushProcess();
  }

  regAfterPhoneNotFound() {
    this.$store.authAndReg.authPhoneNotFound = this.phone;
    this.$router.push('/reg');
  }

  focusOnScroll() {
    this.scrollBlock?.$el.scrollTo({
      top: 200,
      behavior: 'smooth',
    });
  }

  async selectLogin(login: string) {
    this.currentLogin = login;

    if (this.$store.authAndReg.promoCodeValid) {
      return await this.promoCodeRegister();
    } else {
      this.clickEnter();
    }

    this.gaEventAuth({ action: 'Клик по аккаунту (выбор из списка аккаунтов)' });
  }

  async retryTrialStatus() {
    this.isLoading = true;
    try {
      await actions.registration.loadTrialStatusAfterRegistration(this.$store);
      if (!selectors.registration.isAutoTrialStatusUnknownSelector(this.$store)) {
        this.changeStep(AUTH_STEPS.finish);
      }
    } catch (err) {
      if (err?.code === GET_TRIAL_STATUS_ERROR_CODE) {
        // do nothing, just keep showing the same page
      } else {
        log.error(err);
      }
    }
  }

  async promoCodeRegister() {
    try {
      this.$store.registrationResult = await api.auth.registerWithPromoCode({
        params: {
          'contacts-session-token': this.$store.verificationResult.sessionToken,
          'phone-info-token': this.$store.authAndReg.phoneInfoToken,
        },
        data: { promocode: this.$store.authAndReg.promoCodeId },
      });
      this.changeStep(this.steps.promoCodeSuccess);
    } catch (err) {
      const nextStep =
        err?.code === HTTP_CODES.EXISTING_LOGIN
          ? this.steps.promoCodeUserExist
          : this.steps.promoCodeNotFound;

      if (err?.code === HTTP_CODES.EXISTING_LOGIN) {
        this.$store.registrationResult = {
          registeredLogin: err?.existingLogin,
        };
      }

      this.errorText = err?.message;
      this.changeStep(nextStep);
    }
  }

  continueWithoutPromoCode() {
    this.$store.authAndReg.promoCode = '';
    this.$store.authAndReg.promoCodeValid = false;
    this.changeStep(this.steps.start);
  }

  continueWithNewPhone() {
    this.phone = '';
    this.changeStep(this.steps.start);
  }

  async afterCheckCodeIsOk() {
    if (this.isAuth) {
      await actions.authAndReg.getAvailableLogins(this.$store);
      this.$store.authAndReg.logins = this.$store.availableLogins?.options || [];

      if (!this.$store.authAndReg.logins.length) {
        this.changeStep(AUTH_STEPS.phoneNotFound);
      } else if (this.$store.authAndReg.logins.length > 1) {
        this.changeStep(AUTH_STEPS.multipleLogins);
      } else {
        this.clickEnter();
      }
      return;
    }

    if (this.$store.authAndReg.promoCodeValid && this.$store.authAndReg.logins.length <= 1) {
      return await this.promoCodeRegister();
    }

    try {
      this.$_verification_setTimeout(this.changeStep, AUTH_STEPS.outdated);

      if (this.$store.authAndReg.register.providerIdFromUrl) {
        const provider = this.$store.providers.find(
          (provider) => provider.internal_id === this.$store.authAndReg.register.providerIdFromUrl
        );
        this.$store.authAndReg.register.regionId = provider?.regions[0];
        this.$store.authAndReg.register.providerId = provider?.id;
        if (!this.$store.authAndReg.register.providerId) {
          this.showErrorPage(this.getTranslation('unknown_provider'));
        } else {
          await this.finishRegistration();
        }
        return;
      }

      const nextStep = this.$store.authAndReg.recommendProvider
        ? AUTH_STEPS.recommendProvider
        : AUTH_STEPS.skipProvider;
      this.changeStep(nextStep);
    } catch (err) {
      if (err?.code === CONTACT_VERIFICATION_OUTDATED_ERROR_CODE) {
        this.changeStep(AUTH_STEPS.outdated);
      } else if (err?.code === CONTACT_VERIFICATION_ERROR_CODE) {
        this.showErrorPage(err);
      } else {
        throw err;
      }
    }
  }

  async registerWithToken() {
    const data: Record<string, string> = {
      regionId: this.regionId ? this.regionId : '',
    };

    if (
      !this.$store.authAndReg.registrationSettings.includes(
        PHONE_INFO_VERIFICATION_METHODS.fixedProvider
      )
    ) {
      data.externalProviderId = !this.providerId
        ? this.providerName
          ? this.providerName
          : ''
        : this.providerId === '0'
        ? ''
        : this.providerId;
    }

    try {
      await actions.registration.registerWithToken(this.$store, {
        params: this.prepareFinishRegistration(),
        data,
      });
    } catch (err) {
      throw err;
    }
  }

  async finishRegistration() {
    this.isLoading = true;

    try {
      await this.registerWithToken();

      this.gaEventAuth({
        action: `Клик по кнопке "Зарегистрироваться" ${
          this.providerName ? 'после' : 'без'
        } выбора провайдера`,
      });
      this.yaMetrikaGoal('Provider_submit');
      await this.signIn(false);
      if (this.isSmotreshka) {
        await sleep(REGISTRATION_TIMEOUT_AFTER_SIGN_IN);
        await actions.registration.loadSubscriptionOptions(this.$store);
        await actions.registration.loadTrialStatusAfterRegistration(this.$store);
        if (selectors.registration.isAutoTrialStatusUnknownSelector(this.$store)) {
          this.changeStep(AUTH_STEPS.retryTrialStatus);
        } else {
          this.changeStep(AUTH_STEPS.finish);
        }
      } else {
        this.changeStep(AUTH_STEPS.finish);
      }
    } catch (err) {
      if (err?.code === CONTACT_VERIFICATION_OUTDATED_ERROR_CODE) {
        this.changeStep(AUTH_STEPS.outdated);
      } else if (err?.code === GET_TRIAL_STATUS_ERROR_CODE) {
        this.changeStep(AUTH_STEPS.retryTrialStatus);
      } else if (err?.code === HTTP_CODES.EXISTING_LOGIN) {
        this.$store.registrationResult = {
          registeredLogin: err?.existingLogin,
        };
        this.changeStep(AUTH_STEPS.phoneFoundBeforeReset);
      } else {
        this.showErrorPage(err);
      }
      this.isLoading = false;
      throw err;
    }
  }

  async sendSimPassword() {
    this.isLoading = true;
    try {
      this.$store.verificationResult = await api.auth.contactsVerificationSimPassword({
        data: {
          phone: this.phoneCleaned,
          password: this.$store.authAndReg.passwordAuth,
        },
      });

      if (this.$store.authAndReg.promoCodeValid) {
        return await this.promoCodeRegister();
      }

      if (this.isAuth) {
        await this.afterCheckCodeIsOk();
      } else {
        await this.registerWithToken();
        this.changeStep(AUTH_STEPS.finish);
      }
    } catch (err) {
      this.isLoading = false;
      if (err?.code !== HTTP_CODES.EXISTING_LOGIN) {
        this.$store.authAndReg.passwordAuth = '';
      }
      if (err.code === HTTP_CODES.FORBIDDEN) {
        this.errorText = err?.message;
      } else if (err.code === HTTP_CODES.EXISTING_LOGIN) {
        this.$store.registrationResult = {
          registeredLogin: err?.existingLogin,
        };
        if (this.isAuth) {
          await this.signIn(true);
        } else {
          this.changeStep(AUTH_STEPS.phoneFoundBeforeReset);
        }
      } else {
        this.errorText = err.errors[0].msg;
      }
    }
  }

  async signIn(withRedirect = true) {
    let login;

    if (this.$store.authAndReg.logins.length) {
      login = this.currentLogin || this.$store.authAndReg.logins[0]['login'];
      this.gaEventAuth({ action: 'Клик по кнопке "Войти"' });
    } else {
      login = this.$store.registrationResult?.registeredLogin || cleanupPhone(this.phone, false);
      this.gaEventAuth({ action: 'Регистрация нового пользователя' });
    }
    this.yaMetrikaGoal('Reg_completion');

    try {
      await actions.authAndReg.loginWithVerifiedContacts(
        this.$store,
        login,
        this.$ssrContext?.cookiesToSet
      );
      cookieStore.set('welcomeScreenConfirmed', '0');
      this.$store.common.showProfileConfirmationOverlay = false;
      if (withRedirect) {
        await this.redirectAfterSignIn();
      }
    } catch (err) {
      this.showErrorPage(err);
    }
  }

  async redirectAfterSignIn() {
    const backUrl = sessionStore.get('backUrlAfterLogin');
    if (backUrl) {
      await this.$router.push(backUrl);
      sessionStore.remove('backUrlAfterLogin');
    } else {
      await this.goToMainPageWithRouter();
    }
  }

  async clickEnterPromo() {
    this.isLoading = true;
    await this.signIn(false);
    if (this.isSmotreshka) {
      await sleep(REGISTRATION_TIMEOUT_AFTER_SIGN_IN);
      try {
        await actions.registration.loadSubscriptionOptions(this.$store);
        await actions.registration.loadTrialStatusAfterRegistration(this.$store);
        if (!selectors.registration.isAutoTrialStatusUnknownSelector(this.$store)) {
          await this.redirectAfterSignIn();
        }
      } catch (err) {
        await this.redirectAfterSignIn();
        this.changeStep(AUTH_STEPS.retryTrialStatus);
      }
    }
  }

  clickEnter() {
    this.isLoading = true;
    this.signIn();
  }

  handleSwitch() {
    this.$store.isPhoneMode = !this.$store.isPhoneMode;
    this.phone = '';
    this.$store.authAndReg.passwordAuth = '';
    this.$store.incompletePhoneInitialFlag = true;
    this.$store.incompletePhone = false;
    this.errorMessages.length = 0;
    this.gaEventAuth({
      action: `Клик ${
        this.$store.isPhoneMode
          ? '"Я регистрировался по номеру телефона"'
          : '"Я регистрировался по логину"'
      }`,
    });
  }

  getMetaInfo() {
    const page = this.isAuth ? 'register' : 'login';
    return {
      title: this.getTranslation(`${page}_meta_title`),
      meta: [
        {
          name: 'description',
          content: this.getTranslation(`${page}_meta_description`),
        },
        {
          name: 'keywords',
          content: this.getTranslation(`${page}_meta_keywords`),
        },
      ],
    };
  }
}
</script>
