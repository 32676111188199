<template>
  <div class="registration auth-by-login">
    <h4 class="h3 mb-24" v-html="getTranslation('enter_reg')" />

    <div class="description mb-16" v-html="authDescription" />

    <div class="login-type mb-24">
      <span class="link nav-underlined" @click="handleSwitch" v-html="authLoginType" />
    </div>

    <StepAuth :forgot-password="true" :button-text="getTranslation('signin_reg')" />

    <div class="description">
      <div class="register-link-container text-center mt-16 mb-16 px-0">
        <router-link
          to="/reg"
          tag="a"
          class="link nav-underlined"
          @click.native="gaEventAuth({ action: 'Клик “Регистрация”' })"
          v-html="getTranslation('registration_reg')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { offAutocomplete } from 'src/utils/directives';
import Auth from 'src/components/auth/Auth';
import StepAuth from 'src/components/auth/steps/StepAuth.vue';
import logger from 'src/utils/logger';

const log = logger('auth-sm');

@Component({
  components: { StepAuth },
  directives: {
    offAutocomplete,
  },
})
export default class AuthSM extends Auth {
  gaNameComponent = 'login';

  get authDescription() {
    return this.isPhoneMode
      ? this.getTranslation('enter_phone_password')
      : this.getTranslation('enter_login_password');
  }

  get authLoginType() {
    return this.isPhoneMode
      ? this.getTranslation('registered_login')
      : this.getTranslation('registered_phone');
  }

  created() {
    this.$store.isPhoneMode = false;
    this.phone = '';
  }

  async mounted() {
    this.$store.authAndReg.verificationComponentName = 'authentication';
  }

  beforeDestroy() {
    this.flushProcess();
  }

  getMetaInfo() {
    return {
      title: this.getTranslation('login_meta_title'),
      meta: [
        {
          name: 'description',
          content: this.getTranslation('login_meta_description'),
        },
        {
          name: 'keywords',
          content: this.getTranslation('login_meta_keywords'),
        },
      ],
    };
  }

  handleSwitch() {
    this.$store.isPhoneMode = !this.$store.isPhoneMode;
    this.phone = '';
    this.$store.authAndReg.passwordAuth = '';
    this.$store.incompletePhoneInitialFlag = true;
    this.$store.incompletePhone = false;
    this.gaEventAuth({
      action: `Клик ${
        this.$store.isPhoneMode
          ? '"Я регистрировался по номеру телефона"'
          : '"Я регистрировался по логину"'
      }`,
    });
  }
}
</script>
