<template>
  <div class="trial-subscription-offers container-1440">
    <ContentSlider
      type="adaptive"
      class="slider"
      :class="{ centered: offers.length < 4 }"
      :shake-animation="true"
      @gaScroll="gaEvent({ category: 'registration', action: 'Скролл подписок' })"
    >
      <TrialSubscriptionOffer
        v-for="offer in offers"
        :id="offer.id"
        :key="offer.id"
        class="slide col-desktop-3 col-tablet-4 col-mobile-2"
        :active="offer.id === currentOfferId"
        :trial-days="offer.trialDays"
        :rendering-data="offer.renderingData"
        @more="$emit('more')"
      />
    </ContentSlider>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { selectors } from 'src/store/selectors';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import TrialSubscriptionOffer from 'src/components/auth/reg-completed/trials/trials-list/TrialSubscriptionOffer.vue';
import { actions } from 'src/store/actions';
import { SequoiaComponent } from 'src/mixins';

@Component({
  components: { TrialSubscriptionOffer, ContentSlider },
})
export default class TrialSubscriptionOffers extends SequoiaComponent {
  get offers() {
    return selectors.registration.offersSelector(this.$store);
  }

  get activeTrialIdFromAdmin() {
    return selectors.common.activeTrialIdFromAdmin(this.$store);
  }

  get currentOfferId() {
    return selectors.registration.currentOfferIdSelector(this.$store);
  }

  mounted() {
    const activeTrialId = this.offers.find((offer) => offer.id === this.activeTrialIdFromAdmin)?.id;
    this.setCurrentOfferId(activeTrialId || this.offers[1].id);
  }

  setCurrentOfferId(id: string) {
    actions.registration.setCurrentOfferId(this.$store, id);
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.trial-subscription-offers {
  @include tablet {
    width: auto;
    margin: 0 -48px;
  }

  @include mobile {
    width: auto;
    margin: 0 -24px;
  }

  .slider {
    &::v-deep .slider-items {
      padding-top: 20px;
      padding-bottom: 10px;

      @include mobile-and-tablet {
        display: flex;
      }

      @include tablet {
        padding-top: 50px;
      }

      @include mobile {
        padding-top: 30px;
      }
    }

    &.centered::v-deep .slider-items {
      justify-content: center;

      &::after {
        display: none;

        @include mobile-and-tablet {
          display: block;
        }
      }

      @include mobile-and-tablet {
        justify-content: flex-start;
      }
    }
  }

  .slide {
    @include devices-with-touch {
      display: inline-flex !important;
    }
  }
}
</style>
