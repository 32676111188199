<template>
  <div class="step-captcha">
    <h4 class="h3 mb-24 promocode-title">
      <span v-html="getTranslation('registration_reg')" />
      <BadgeSequoia
        v-if="$store.authAndReg.promoCodeValid"
        class="promocode-badge"
        :text="$store.authAndReg.canonicalValue"
        :icon="IconGift"
        type="accent"
      />
    </h4>

    <div class="description body2 mb-24">
      <span v-html="getTranslation('registration_captcha_description')" /><br />
      <span
        class="link nav-underlined"
        @click="changePhoneClick"
        v-html="getTranslation('registration_captcha_change_phone')"
      />
    </div>

    <form @submit.prevent="start">
      <InputText
        ref="captchaValue"
        v-model="captchaValue"
        input-category="floating"
        size="medium"
        required
        :with-captcha="true"
        data-cy="captcha"
        :use-vercont-server="useVercontServer"
        :maxlength="6"
        :label="getTranslation('code_reg')"
        :description="getTranslation('enter_digits_left')"
        :message="messageForCaptchaInput"
        :status="statusForCaptchaInput"
        @focus="gaEventOnceAuth({ action: 'Фокус в поле Капча' })"
      />

      <ButtonDefault
        class="mt-24"
        size="medium"
        :full-width="true"
        data-cy="captcha"
        :is-loading="isLoading"
        :disabled="!captchaValue || isLoading"
      >
        <span v-html="getTranslation('next_reg')" />
      </ButtonDefault>
    </form>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';
import { Prop, Ref, Vue } from 'vue-property-decorator';

@Component
export default class StepCaptcha extends Auth {
  @Ref('captchaValue')
  refCaptchaValue?: Vue;

  @Prop({ default: false })
  useVercontServer!: boolean;

  mounted() {
    (this.refCaptchaValue?.$refs.input as HTMLInputElement | undefined)?.focus();
  }
}
</script>
