<template>
  <div>
    <div v-if="!initialLoading" class="registration">
      <h4 class="h3 mb-24" v-html="getTranslation('forgot_password_reg')" />

      <StepStart
        v-if="currentStep === steps.start"
        :description="startStepDescription"
        :input-phone-message="getTranslation('incomplete_phone_reg')"
        :change-phone-mode="isSmotreshka"
        :use-vercont-server="isPhoneMode"
        :show-promo-code-button="false"
        @changePhoneMode="changePhoneMode"
      />

      <StepCheckCode v-if="currentStep === steps.checkCode" />

      <StepCaptcha v-if="currentStep === steps.captcha" :use-vercont-server="isPhoneMode" />

      <StepMultipleLogins
        v-if="currentStep === steps.multipleLogins"
        :description="getTranslation('select_login')"
        @selectLogin="selectLogin"
      />

      <StepOther
        v-if="currentStep === steps.phoneFoundBeforeReset"
        :link-in-description="getTranslation('send_new_pwd')"
        :button-text="getTranslation('login_without_password')"
        @linkInDescription="sendNewPassword"
        @clickButton="signIn"
      >
        <template v-slot:description v-html="getTranslation('send_new_pwd_sms')" />
      </StepOther>

      <StepOther
        v-if="currentStep === steps.phoneFoundAfterReset"
        :button-text="getTranslation('signin_reg')"
        @clickButton="signIn"
      >
        <template v-slot:description>
          <span v-html="getTranslation('login_pwd_sent_number')" />
          <br />
          <b v-text="formatPhone(phone)" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.phoneNotFound"
        :link-in-description="getTranslation('using_email')"
        @linkInDescription="restartWithEmail"
      >
        <template v-slot:description>
          <span v-html="getTranslation('unfortunately')" />&#32;<b
            v-text="formatPhone(phone)"
          />&#32;<span v-html="getTranslation('not_registered')" />&#32;<span
            v-html="getTranslation('recover_password')"
          />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.emailFinish"
        :button-text="getTranslation('back_to_main')"
        @clickButton="goToMainPage"
      >
        <template v-slot:description>
          <span v-html="getTranslation('link_sent')" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.outdated"
        :button-text="getTranslation('try_again_2')"
        @clickButton="flushProcess"
      >
        <template v-slot:description>
          <span v-html="getTranslation('session_time_out')" />
          <br />
          <span v-html="getTranslation('try_again_1')" />
        </template>
      </StepOther>

      <StepOther
        v-if="currentStep === steps.error"
        :title="getTranslation('something_went_wrong')"
        :error="true"
        :button-text="getTranslation('try_again_2')"
        @clickButton="flushProcess"
      />
    </div>
    <LoaderSpinner v-if="initialLoading" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';

import StepStart from 'src/components/auth/steps/StepStart.vue';
import StepCaptcha from 'src/components/auth/steps/StepCaptcha.vue';
import StepCheckCode from 'src/components/auth/steps/StepCheckCode.vue';
import StepMultipleLogins from 'src/components/auth/steps/StepMultipleLogins.vue';
import StepOther from 'src/components/auth/steps/StepOther.vue';
import { AUTH_STEPS, REGISTRATION_SMS_CODE_LENGTH } from 'src/constants';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';

@Component({
  components: {
    StepStart,
    StepCaptcha,
    StepCheckCode,
    StepMultipleLogins,
    StepOther,
  },
})
export default class ResetPasswordSM extends Auth {
  initialLoading = true;
  nextStepAfterGetLogins = AUTH_STEPS.phoneFoundBeforeReset;

  @Watch('checkCode')
  onCheckCode(val: string) {
    if (this.isLoading) {
      return;
    }

    this.checkCode = this.checkCode.replace(/\D/g, '');

    if (val.length === REGISTRATION_SMS_CODE_LENGTH) {
      this.isLoading = true;
      this.errors.length = 0;
      this.errorMessages.length = 0;
      this.verifyAndGetLogins(this.checkCode);
    }
  }

  get startStepDescription() {
    return this.getTranslation(
      this.isPhoneMode ? 'phone_number_confirmation' : 'login_confirmation'
    );
  }

  async mounted() {
    this.isLoading = true;
    this.$store.isPhoneMode = true;
    this.$store.authAndReg.gaNameComponent = 'forgotten_password';
    this.$store.authAndReg.verificationComponentName = 'registration';
    this.$store.isPhoneMode = true;

    await this.checkUseRecaptcha();
    this.initialLoading = false;
    this.isLoading = false;

    this.$nextTick(() => {
      this.$store.authAndReg.captchaRefreshValue++;
    });
  }

  beforeDestroy() {
    this.flushProcess();
  }

  changePhoneMode() {
    this.$store.isPhoneMode = !this.$store.isPhoneMode;
    this.useRecaptcha = this.isPhoneMode;
    this.phone = '';
    this.email = '';
    this.$store.incompletePhoneInitialFlag = true;
    this.$store.incompletePhone = false;
    this.$nextTick(() => {
      this.$store.authAndReg.captchaRefreshValue++;
    });

    this.gaEventOnceAuth({
      action: `Клик «Я регистрировался по ${this.isPhoneMode ? 'номеру телефона' : 'логину'}»`,
    });
  }

  restartWithEmail() {
    this.$store.isPhoneMode = false;
    this.useRecaptcha = false;
    this.flushProcess();
    this.gaEventAuth({ action: 'Клик "Восстановить по Email"' });
  }

  async signIn() {
    this.isLoading = true;

    const login = this.currentLogin || this.$store.authAndReg.logins[0]['login'];
    try {
      await actions.authAndReg.loginWithVerifiedContacts(
        this.$store,
        login,
        this.$ssrContext?.cookiesToSet
      );
      this.goToMainPage();
      this.gaEventAuth({ action: 'Клик "Войти" после смены пароля' });
    } catch (err) {
      this.showErrorPage(err);
    }
  }

  selectLogin(login: string) {
    this.currentLogin = login;
    this.sendNewPassword();
  }
}
</script>
