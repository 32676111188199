<template>
  <div class="step-check-code">
    <h4 v-if="title" class="h3 mb-24 promocode-title">
      <span v-html="title" />
      <BadgeSequoia
        v-if="$store.authAndReg.promoCodeValid"
        class="promocode-badge"
        :text="$store.authAndReg.canonicalValue"
        :icon="IconGift"
        type="accent"
      />
    </h4>

    <div class="description with-phone body1">
      <span v-html="getTranslation('sms_sent')" />&#32;
      <br />
      <b v-text="formatPhone(phone)" />
    </div>

    <div class="description body1 mb-24">
      <span v-html="getTranslation('wrong_number')" />&#32;
      <span
        v-if="timerId && waitingTime"
        v-html="getTranslation('timeout_enter_another').replace('%time%', waitingTime.toString())"
      />
      <span
        v-else
        class="link nav-underlined"
        @click="changePhoneClick()"
        v-html="getTranslation('enter_another')"
      />
    </div>

    <div class="code-check-block">
      <InputText
        ref="smscode"
        v-model="checkCode"
        input-category="floating"
        size="medium"
        required
        type="number"
        :status="errorMessages.length ? 'error' : undefined"
        :message="errorMessages.join(', ')"
        :maxlength="smsCodeMaxLength"
        :label="getTranslation('code_from_sms')"
        :disabled="isLoading"
        @focus="gaEventOnceAuth({ action: 'Фокус в поле кода из смс' })"
      />

      <ButtonDefault
        v-if="withResendCode"
        size="medium"
        view="secondary"
        :full-width="true"
        :is-loading="isLoading && !waitingTime"
        :disabled="isLoading || (!!timerId && !!waitingTime)"
        @click="retry"
      >
        <span v-html="getTranslation('resend_code_more')" />
      </ButtonDefault>

      <ButtonDefault
        v-if="isMts"
        size="medium"
        :full-width="true"
        :is-loading="isLoading"
        :disabled="isSendCodeButtonDisabled"
        @click="$emit('clickButton')"
      >
        <span v-html="getTranslation('sms_code_submit')" />
      </ButtonDefault>
    </div>

    <div
      v-if="timerId && waitingTime && withResendCode"
      class="description caption2 mt-16 ml-16"
      v-html="waitingTimeText"
    />

    <LoaderSpinner v-if="isLoading && !isMts" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop, Ref, Vue } from 'vue-property-decorator';
import { AUTH_STEPS, MTS_AUTH_SMS_CODE_LENGTH, REGISTRATION_SMS_CODE_LENGTH } from 'src/constants';
import Auth from 'src/components/auth/Auth';
import { wlDetector } from 'src/utils';

@Component
export default class StepCheckCode extends Auth {
  @Prop({ default: '' })
  title!: string;

  @Ref('smscode')
  refSmscode?: Vue;

  @Prop({ default: true })
  withResendCode!: boolean;

  get waitingTimeText() {
    return `${this.getTranslation('waiting_resend_code')} ${this.waitingTime} ${this.getTranslation(
      'seconds'
    )}`;
  }

  get isMts() {
    return wlDetector().isMts;
  }

  get smsCodeMaxLength() {
    return this.isMts ? MTS_AUTH_SMS_CODE_LENGTH : REGISTRATION_SMS_CODE_LENGTH;
  }

  get isSendCodeButtonDisabled() {
    return this.isLoading || this.checkCode.length < REGISTRATION_SMS_CODE_LENGTH;
  }

  mounted() {
    if (this.withResendCode) {
      this.checkCode = '';
      this.$_verification_startTimer();
      this.$_verification_setTimeout(this.changeStep, AUTH_STEPS.outdated);
    }

    (this.refSmscode?.$refs.input as HTMLInputElement | undefined)?.focus();
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.code-check-block {
  display: flex;
  @include mobile {
    display: block;
    width: 100%;
  }

  &::v-deep {
    .input-block {
      width: 156px;
      margin-right: 16px;

      @include mobile {
        width: 100%;
        margin-right: 0;
      }
    }

    .button {
      width: calc(100% - 136px);
      overflow: hidden;

      @include mobile {
        width: 100%;
        margin-top: 12px;
      }
    }
  }
}

.with-phone {
  b {
    white-space: nowrap;
  }

  br {
    @include mobile {
      display: none;
    }
  }
}

// --------------------------------------------
// Theme Colors
// --------------------------------------------
.light {
  .description {
    color: var(--c-light-font-tertiary);
  }
}

.dark {
  .description {
    color: var(--c-dark-font-tertiary);
  }
}
</style>
