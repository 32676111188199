<template>
  <div class="success-container body1">
    <IconSVG :svg="IconCheckCircled" class="color-success" />
    <span v-html="message" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import IconCheckCircled from 'src/svg/check-circled.svg';
import IconSVG from 'src/components/IconSVG.vue';

@Component({
  components: { IconSVG },
})
export default class SuccessMessage extends Vue {
  IconCheckCircled = IconCheckCircled;

  @Prop()
  message!: string;
}
</script>

<style lang="scss" scoped>
.success-container {
  margin: 32px 0;
  text-align: center;
}
</style>
